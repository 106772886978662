
import { Injectable, Provider } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/_helperServices/authService.service';
import { SettingService } from '@app/_helperServices/setting.service';
import { MasterRepo } from '@app/_services/masterRepo.service';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TAcList } from '../interfaces/Account.interface';
import { IDivision } from '../interfaces/commonInterface.interface';
import { Filter } from '../interfaces/filter.interface';
import { PREFIX } from '../interfaces/Prefix.interface';
import { Product, AltSellingPrice } from '../interfaces/ProductItem';
import { Transporter_Eway, TrnMain, TrnMain_AdditionalInfo, TrnProd, Trntran, VoucherTypeEnum, AlternateUnit, IMPORT_DETAILS, BatchRequireEnum, AllowNegativeEditEnum } from '../interfaces/TrnMain';
import { AlertService } from '../shared/alert/alert.service';
import { SpinnerService } from '../shared/spinner/spinner.service';
import * as uuid from 'uuid';
import { PriceRate } from '../interfaces/PriceRate.interface';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { BarCodeInterface } from '../interfaces/BarCodeInsertInterface';
import { PreventNavigationService } from '../services/navigation-perventor/navigation-perventor.service';
import { takeRight } from 'lodash';
import { componentFactoryName } from '@angular/compiler';
import { userRightAuthorizationService } from './userRightAuthorizaton.service';
import { APP_MODE } from 'src/app/_helperServices/appMode.config';
import { environment } from '@environments/environment';

@Injectable()
export class TransactionService {
  TOTALALLDISCOUNT: number;
  TrnMainObj: TrnMain = <TrnMain>{};
  FilterObj: Filter = <Filter>{};
  Warehouse: string;
  pageHeading: string;
  buttonMode: string;
  prefix: PREFIX = <PREFIX>{};
  division: IDivision = <IDivision>{};
  saveDisable: boolean;
  differenceAmount: number = 0;
  trntranTotal: number = 0;
  CustomerObjForDiscount: any = <any>{};
  activeurlpath: any;
  crTotal: number = 0;
  drTotal: number = 0;
  salesMode: string;
  cnMode: string;
  warrentyUpToDate: Date;
  warrentyVchrList: any[];
  buttonHeading: string = 'Reference No';
  QuantityMsg: string;
  piceDuplicateMessage: string;
  inputCode: boolean;
  qtyCheckForPrint: boolean = false;
  customerEvent: boolean = false;
  printModeForInvoice: any;
  prodListForUnfinedBill: boolean = false;
  mulitplePriceItemList = [];
  memberPopupForRefer: boolean = false;
  saveChanges: boolean = true;
  apiUrl: any;
  PetrolQuant: number;
  accountListSubject: BehaviorSubject<TAcList[]> = new BehaviorSubject<
    TAcList[]
  >([]);
  accountListObersver$: Observable<
    TAcList[]
  > = this.accountListSubject.asObservable();
  TableAcHeader: string = 'Description';
  prodListMode: ProdListMode;
  prodDisableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  prodDisable$: Observable<boolean> = this.prodDisableSubject.asObservable();
  cnReturnedProdList: TrnProd[];
  referenceNoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  referenceNo$: Observable<string> = this.referenceNoSubject.asObservable();
  public PrintStuffSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public PrintStuff$: Observable<any> = this.PrintStuffSubject.asObservable();
  currentIndexProductDetails = [];

  supplierwiseItem: any = 0;
  returnVoucherMain: any = <any>{};
  DefaultSellableWarehouse: string;
  partyList: any[] = [];
  userProfile: any = <any>{};
  CustomerList: any[] = [];
  doubleClick: boolean = false;
  customerListInPopup: boolean = false;
  salesmanID: string;
  salesmanName: string;
  routeCode: string;
  customerDoubleClick: boolean = false;
  saveButton: boolean = false;
  billtoStatus: boolean = false;
  billunitevent: boolean = false;
  // extra settings for performa invoice
  showPerformaApproveReject: boolean = false; // initially false
  disableProd: boolean = false;
  disableAllFieldinPurchase = false;
  disableAllFieldinSales = false;
  quantityExceedCheck:string="";  
  reverseViewTag:string="";  

  fromNotification: boolean = false;
  billTo: string;
  BarcodeFromScan: string;
  ItemsListForMultiItemBarcode: any[] = [];
  voucherNoHeader: string = 'Bill No';
  availableSchemes: any[] = [];
  duplicateBarCodeMessage: string;
  public barCodes:BarCodeInterface=<BarCodeInterface>{};

  showBarCodePopUPinViewMode: boolean = false;
  showBarCodePopUPSubject: Subject<any> = new Subject<any>();
  barcodeObj$: Observable<any> = this.showBarCodePopUPSubject.asObservable();
  hideProductDescriptionPopUp: boolean = false;
  userSetting: any;
  showPriceChangeDetail: boolean = false;
  showPriceChangeDetailINDisc: boolean = false;
  selectedProdcutRecMargin: any;
  priceChangeList: any = [];
  showBatchWiseQtyEntryPopup: String;
  static ReportMainService: Provider;
  ReturnStockObj: any = <any>{};
  urlpath: any = '';
  itemselectevent: boolean = false;
    public purchaseOrderQty:number;
    public purchaseOrderRate:number;
    public produstWiseOrderUnit:string;
  showDiscountAuth: boolean=false;
  disableOnApproval:boolean = false;
  disableOnGRNload: boolean = false;
  productionEntryPrefix: string = 'PD'
  disableNepaliDate: boolean = false;
  disableRateOnPOselect: boolean = false;
  disableSupplierInfo: boolean = false;
  disableCustomerInfo: boolean = false;
  checkWhileSave: boolean = false;
  itemname: string;
  disableOnPerFormaSelect: boolean = false;
  disableRateOnGRNload: boolean = false;
  TempTrnMainObj: TrnMain = <TrnMain>{};
  disablePaymentTerms: boolean = false;
  checkBatchWiseApiResponses:boolean = false;
  disableTranferToFromReq: boolean = false;
  afterAutoloadCheck: boolean = false;
  disbaleReqFrom: boolean = false;
  showNegativeBilling: boolean=false;

  showNegativeBillingInConsumption: boolean=false;

  showNegativeBillingAuthForMain: boolean = false;
  AppMode: string;

  disableConsumption:boolean = false; 
  allowDescriptionEdit: boolean = false;
  disAuthObj = {
    username: "",
    password: "",
    discount: 0,
    discountType: "0",
    remarks: ""
  };


  constructor(
    private masterService: MasterRepo,
    private setting: SettingService,
    private authservice: AuthService,
    private alertService: AlertService,
    private loadingService: SpinnerService,
    private arouter: ActivatedRoute,
    private router: Router,
    public preventNavigationService: PreventNavigationService
  ) {
    this.AppSettings = setting.appSetting;
    this.userProfile = authservice.getUserProfile();
    this.userSetting = authservice.getSetting();
    console.log("check app mode", environment.appMode);
    this.AppMode = environment.appMode;
    // console.log("CHECK APP MODE", APP_MODE, APP_MODE.applicationMode);
    // this.masterService.getProductItemList().subscribe();

     this.activeurlpath = arouter.snapshot?.url[0]?.path;
    this.TrnMainObj.AdditionalObj = {} as TrnMain_AdditionalInfo;

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        let url = ev.url.split("/");
        this.urlpath = url[url.length - 1];
      }
    });

    this.masterService.getVariantConfiguration();

  }

  trnmainBehavior: BehaviorSubject<TrnMain> = new BehaviorSubject(<TrnMain>{});
  loadDataObservable: Observable<TrnMain> = this.trnmainBehavior.asObservable();
  PMode: string; // p for party mode and c for customer mode
  MaxTotalAmountLimit: number = 5000;
  DefaultCustomerAc: string = '';
  SettlementNo: any;
  INVMAIN: any;
  AppSettings: any;
  CashList: any[] = [];
  PurchaseAcList: any[] = [];
  paymentmodelist: any[] = [];
  paymentAccountList: any[] = [];
  settlementList: any[] = [];
  loadData(VCHR, division, phiscalid) {
      console.log("mode",this.buttonMode);
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid,'','','',this.buttonMode).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          console.log("LOAD DATA", this.TrnMainObj);
          
          this.TrnMainObj.Mode = this.buttonMode;
          this.TrnMainObj.GROSSAMNT = (this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase && this.TrnMainObj.VATBILL == 1) ? data['result'].NONTAXABLE : this.TrnMainObj.ProdList.map(x => x.RATE * x.Quantity).reduce((sum,init)=> sum+init,0);

          this.TrnMainObj.TOTALQTY = 0;
          data['result'].ProdList.forEach(x => this.TrnMainObj.TOTALQTY += x.Quantity);

          this.TrnMainObj.TOTALSQTY = 0;
          data['result'].ProdList.forEach(x=> this.TrnMainObj.TOTALSQTY += x.ALTERNATEQUANTIY);

          if(this.TrnMainObj.VoucherType == 88){
            this.TrnMainObj.TOTALCONSUMPTIONQTY = 0;
            this.TrnMainObj.CONSUMPTIONGROSS = 0;
            data['result'].ConsumptionProdList.forEach(x => {this.TrnMainObj.TOTALCONSUMPTIONQTY += x.Quantity, this.TrnMainObj.CONSUMPTIONGROSS += x.Quantity* x.RATE});
         
  
          }

          if (this.urlpath == "purchase-import") {
            this.TrnMainObj.CNDN_MODE = 10;
            this.pageHeading = "IMPORT PURCHASE";
            this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE";
          }else if(this.urlpath == "transfer-out-approval" || this.urlpath =="stock-issue-approval"){
            this.TrnMainObj.Mode = "NEW";
          }else if (this.urlpath == "purchase-import-order") {
            this.TrnMainObj.CNDN_MODE = 10;
            this.pageHeading = "IMPORT PURCHASE ORDER";
            this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE_ORDER";
          }
          if (this.TrnMainObj.CHALANNOPREFIX != null && this.TrnMainObj.CHALANNOPREFIX !== '') {
            if (this.TrnMainObj.CHALANNO != null || this.TrnMainObj.CHALANNO !== '') {
              let prefixFromChalan = this.TrnMainObj.CHALANNO.substring(0, 3);
              if (prefixFromChalan != null) {
                if (this.TrnMainObj.CHALANNOPREFIX.toUpperCase() === prefixFromChalan.toUpperCase()) {
                  this.TrnMainObj.CHALANNO = this.TrnMainObj.CHALANNO.substring(3);
                }
              }
            } else {
              this.alertService.info('Chalan No Could not be Null');
            }
          }

          if(this.urlpath == "add-membership-sales-invoice"){
            this.TrnMainObj.AdditionalObj.tag = "MembershipSalesInvoice";
          }

          if (this.TrnMainObj.TransporterEway == null) {
            this.TrnMainObj.TransporterEway = <any>{};
          }
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
            this.TrnMainObj.Mode = 'EDIT';
            this.TrnMainObj.ProdList.forEach(element => {
              element.inputMode = true;
            });
          }

          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
            if (this.TrnMainObj.AdditionalObj.INVNO != null
              && this.TrnMainObj.AdditionalObj.INVNO != undefined && this.TrnMainObj.AdditionalObj.INVNO != "") {
              this.disableAllFieldinPurchase = true;
              this.disableAllFieldinSales = true;
              this.alertService.info("This voucher can not be Edit");
            }
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase &&  this.TrnMainObj.Mode == 'EDIT' && (this.TrnMainObj.DCRATE > 0 || this.TrnMainObj.FLATDCAMNT > 0)){
       
            this.alertService.info("Flat discount is applied in this voucher. Remove flat discount to continue to edit..")
          }
  
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.CNDN_MODE === 10){
            this.TrnMainObj.AdditionalObj.tag = 'IMPORT_PURCHASE_ORDER';
          }


          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
          }

          // if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote){
          //   console.log("DC AMOUNT", this.TrnMainObj.DCAMNT);
            this.TrnMainObj.TOTALDISCOUNT = this.TrnMainObj.DCAMNT;
          // }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.activeurlpath != 'purchase-import-order'){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
            this.TrnMainObj.TAXABLE = this.TrnMainObj.ProdList.map(x => x.TAXABLE).reduce((sum,init)=> sum+init,0);
            
            this.TrnMainObj.NETWITHOUTROUNDOFF = this.TrnMainObj.TOTAMNT + this.TrnMainObj.VATAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.TOTAMNT;
            if(this.TrnMainObj.VATAMNT != 0 ){
              this.TrnMainObj.TAXABLE = this.TrnMainObj.GROSSAMNT;

            }
            this.TrnMainObj.NETWITHOUTROUNDOFF = this.TrnMainObj.NETAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry || this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn){
            this.TrnMainObj.GROSSAMNT = this.TrnMainObj.NETAMNT;
          }

          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry){
            if(!this.TrnMainObj.INVOICETYPE){
              this.TrnMainObj.INVOICETYPE = 'TransferOut';
            }
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            if(this.TrnMainObj.ProdList[i].MFGDATE != null){
              this.TrnMainObj.ProdList[i].MFGDATE = this.userSetting.enableDayWiseMfgEXPDate == 0? this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0,7) :this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0,10);
            }else{
              this.TrnMainObj.ProdList[i].MFGDATE = '';
            }
            if(this.TrnMainObj.ProdList[i].EXPDATE != null){
              this.TrnMainObj.ProdList[i].EXPDATE = this.userSetting.enableDayWiseMfgEXPDate == 0? this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0,7) :this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0,10);
            }else{
              this.TrnMainObj.ProdList[i].EXPDATE = '';
            }
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;

            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;

            if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote){
              this.TrnMainObj.ProdList[i].GROSSAMNT = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[i].RATE;
            }

            if(this.TrnMainObj.Mode == 'DRILL'){
              if(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY) ==0){
                this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY = this.TrnMainObj.ProdList[i].AltQty;
              }
            }

      
          }

      
          this.prePareVariantDescription();

          if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase && this.TrnMainObj.CNDN_MODE ==10){
             this.calculateImportProdTotalValue();
          }


          this.Warehouse = this.TrnMainObj.MWAREHOUSE;
          if (
            !this.Warehouse &&
            this.TrnMainObj.ProdList &&
            this.TrnMainObj.ProdList.length > 0
          ) {
            this.Warehouse = this.TrnMainObj.ProdList[0].WAREHOUSE;
          }
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
          this.TrnMainObj.CHEQUEDATE =
            this.TrnMainObj.CHEQUEDATE == null
              ? ''
              : this.TrnMainObj.CHEQUEDATE.toString().substring(0, 10);
          this.TrnMainObj.DeliveryDate =
            this.TrnMainObj.DeliveryDate == null
              ? ''
              : this.TrnMainObj.DeliveryDate.toString().substring(0, 10);
            this.TrnMainObj.ORDEXPDATE = 
              this.TrnMainObj.ORDEXPDATE == null
              ?''
              :this.TrnMainObj.ORDEXPDATE.toString().substring(0,10);

    
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }

  prePareVariantDescription(){
    for(let i in this.TrnMainObj.ProdList){

      this.TrnMainObj.ProdList[i].VARIANTDESCA = "";
      if(this.TrnMainObj.ProdList[i].HASVARIANT == 1){
        // console.log("VARAINT LIST IN LOAD", this.TrnMainObj.ProdList[i].VARIANTLIST);
        for (var attribute in this.TrnMainObj.ProdList[i].VARIANTLIST) {
          if (['QTY', 'PRATE', 'MRP', 'SRATE','BARCODE'].indexOf(attribute) == -1) {
            console.log("attr",attribute,this.TrnMainObj.ProdList[i].VARIANTLIST,attribute);
            this.TrnMainObj.ProdList[i].VARIANTDESCA = this.TrnMainObj.ProdList[i].VARIANTDESCA + `<b>${this.getVariantNameFromId(attribute)}</b>:${this.TrnMainObj.ProdList[i].VARIANTLIST[attribute].NAME} &nbsp;`
          }
        }
        // console.log("on eidt",this.TrnMainObj.ProdList[i].VARIANTDESCA)
      }
    }
  }

 



  NegativeBillAuthConfirm(activerowIndex:number) {

    this.loadingService.show("Authorizing Please wait");
    this.masterService.NegativeBillingAuth(this.disAuthObj).subscribe(
      (data: any) => {
        this.disAuthObj =  {
          username: "",
          password: "",
          discount: 0,
          discountType: "0",
          remarks: ""
        };
        
        console.log("Negative stock responee", data);
        this.loadingService.hide();
        if (data.status == "ok") {
          if(data.result == "ALLOW"){
            this.alertService.success("User Right Granted !")
            this.showDiscountAuth = false;
            this.showNegativeBilling = false;
            this.showNegativeBillingInConsumption = false;
            this.TrnMainObj.ProdList[activerowIndex].IsAuthorizeForNegative = true;

            setTimeout(()=>{
              document.getElementById('rate' + activerowIndex).focus();
            },1000)

            this.alertService.emitShowHideSubject.subscribe(
              (res) => {
                setTimeout(() => {
                  document.getElementById('rate' + activerowIndex).focus();
                }, 0);
              }
            );

          }else{
            this.alertService.warning("this user has no rights to do negative stock billing !");
            this.showNegativeBilling = false;
            this.showNegativeBillingInConsumption = false;
            this.TrnMainObj.ProdList[activerowIndex].ALTERNATEQUANTIY = 0;

            setTimeout(()=>{
              document.getElementById('alternateqty' + activerowIndex).focus();
            },1000)

            this.alertService.emitShowHideSubject.subscribe(
              (res) => {
                setTimeout(() => {
                  document.getElementById('alternateqty' + activerowIndex).focus();
                }, 0);
              }
            );

          }
        } else {
          this.alertService.warning("Authorization Failed");
          this.showDiscountAuth = false;
          this.showNegativeBilling = false;
          this.showNegativeBillingInConsumption = false;
            this.TrnMainObj.ProdList[activerowIndex].ALTERNATEQUANTIY = 0;
            this.alertService.emitShowHideSubject.subscribe(
              (res) => {
                setTimeout(() => {
                  document.getElementById('alternateqty' + activerowIndex).focus();
                }, 0);
              }
            );


        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
      }
    );

  }


  onApplyClicked() {
    this.disableProd = true;
    console.log("r1");
    
    // this.TrnMainObj.AdditionalObj.AdditionalObj.BEFOREFLATDISCRATE = 0;
    console.log("on APPLY CLICKED", this.nullToZeroConverter(this.TrnMainObj.DCRATE))
    if (this.nullToZeroConverter(this.TrnMainObj.DCRATE) !== 0) {
      console.log("DISCOUNT RATE OVERWRITE")
      let disLimit = this.masterService.userProfile.roleRights.find(x=> x.right === 'DISLIMIT');
      if(disLimit){
        if(this.nullToZeroConverter(this.TrnMainObj.DCRATE)> disLimit.value){
          
          this.alertService.warning(`Sorry, Discount Limit of ${disLimit.value}%, exceeded !`);
        }
      }
      
      this.FlatDiscountPercentChange();
    } else {      
      console.log("DISCOUNT AMOOUNT OVERWRITE")
      this.TrnMainObj.ProdList.forEach(x =>{ x.INDDISCOUNT=0, x.DISCOUNT=0});  
      if(this.activeurlpath === 'abbreviated-sales-invoice' || ( this.userSetting.EnableInDisCalculationInTi == 1 && this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice  ) ){        
        
        let checkIndDiscout = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.INDDISCOUNTRATE) > 0 || this.nullToZeroConverter(x.INDDISCOUNT) > 0)[0];
    
        if (checkIndDiscout != null && checkIndDiscout) {
          if (confirm("Are you sure you want ko give individual discount")) {
             this.TrnMainObj.ProdList.filter(x => {x.INDDISCOUNTRATE=0,x.INDDISCOUNT=0} );
            this.calculateInclusiveDiscout(this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT));
            this.ReCalculateBillWithNormal();
          }
        }else{
          this.calculateInclusiveDiscout(this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT));
          this.ReCalculateBillWithNormal();
        }

      }else{
        this.FlatDiscountAmountChange();
      }
            
   
    }

    if(this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)==0 && this.nullToZeroConverter(this.TrnMainObj.DCRATE)==0){
     this.onClearFlatDisc();
    }

  }

  clearFlatPercentageAndAmount(){
    console.log("focus out",this.TrnMainObj.FLATDCAMNT,this.TrnMainObj.DCRATE);
    if(this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT)==0 && this.nullToZeroConverter(this.TrnMainObj.DCRATE)==0){
      this.onClearFlatDisc();
     }
  }



  onClearFlatDisc(){
      this.TrnMainObj.ProdList.forEach(x=>{
        x.INDDISCOUNT = 0;
        x.INDDISCOUNTRATE = 0;
      });

    this.ReCalculateBillWithNormal();
  }

  beforeDiscFlat(){
    console.log("BEFORE FLAT DISC", this.TrnMainObj.DCRATE, this.TrnMainObj.TOTALFLATDISCOUNT);
    this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE = 0;
    if(this.TrnMainObj.DCRATE){
      this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = this.TrnMainObj.DCRATE;
    }else{
      let totalGrossAmount = 0;
      // this.TrnMainObj.ProdList.forEach( x => 
      //   totalGrossAmount += x.ALTERNATEQUANTIY * x.ALTRATE);
      this.TrnMainObj.ProdList.forEach( x =>{
        if((x.MENUCODE != null) && (x.MENUCODE != undefined) ){
          console.log("ALTERNATE QTY", x.ALTERNATEQUANTIY, "RATE", x.ALTRATE, "indv gross",(x.ALTERNATEQUANTIY*x.ALTRATE));
          totalGrossAmount += (x.ALTERNATEQUANTIY*x.ALTRATE);
        }
        
      })
      console.log("totalflatdisc", this.TrnMainObj.TOTALFLATDISCOUNT, "total gross amount", totalGrossAmount);
      if(this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) > 0){
        this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = (this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) * 100) / this.nullToZeroConverter(totalGrossAmount);
        console.log(" BEFORRE IND DIS", this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE);
      }
    }
    this.ReCalculateBillWithNormal();

  }

  afterDiscFlat(){
    console.log("AFETR FLAT DISC", this.TrnMainObj.DCRATE, this.TrnMainObj.TOTALFLATDISCOUNT);

    this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = 0;
    if(this.TrnMainObj.DCRATE){
      this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE = this.TrnMainObj.DCRATE;
    }else{
      let totalGrossAmount = 0;
      this.TrnMainObj.ProdList.forEach( x =>{
        if((x.MENUCODE != null) && (x.MENUCODE != undefined) ){
          // console.log("alternate quantity", x.ALTERNATEQUANTIY, "altrrate", x.ALTRATE,"amount", x.AMOUNT)
          totalGrossAmount += ((x.ALTERNATEQUANTIY*x.ALTRATE)- (x.INDDISCOUNT));
        }
        
      });
      if(this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) > 0){
        this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE = (this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) * 100) / this.nullToZeroConverter(totalGrossAmount);
       
      }
    }
    this.ReCalculateBillWithNormal();
  }

  calculateInclusiveDiscout(discountAmount:number):void{
    if(discountAmount){
      
      let rawTaxable = this.TrnMainObj.ProdList.filter(x => x.ISVAT ==1).map(x =>x.AMOUNT).reduce((sum,initail)=> sum+initail,0);
      let rawNonTaxable = this.TrnMainObj.ProdList.filter(x => x.ISVAT ==0).map(x =>x.AMOUNT).reduce((sum,initail)=>sum+initail,0);

      this.TrnMainObj.ProdList.forEach(x =>  { 
        if(x.ISVAT ==1){
          x.INDDISCOUNT = ((x.AMOUNT)/(rawTaxable+rawNonTaxable))* (discountAmount/this.userSetting.VatConRate);
        }else{
          x.INDDISCOUNT = (x.AMOUNT/(rawNonTaxable+rawTaxable))* discountAmount;
        }
      }
      ) 
    }   else{
      this.TrnMainObj.ProdList.forEach(x=>{
        x.INDDISCOUNTRATE = 0;
        x.INDDISCOUNT = 0;
      })
    }     
  }

  onRevertClicked() {
    this.disableProd = true;
    // console.log("on revert clicked", this.TrnMainObj.AdditionalObj.AdditionalObj.BEFOREFLATDISCRATE, this.TrnMainObj.AdditionalObj.AdditionalObj.AFTERFLATDISCRATE);
    if(this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE)== 0 && this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE) == 0){
      this.TrnMainObj.ProdList.forEach(x => { x.INDDISCOUNT = 0,x.FLATDISCOUNT = 0, x.INDDISCOUNTRATE = 0, x.CRATE = x.RATE });
    }else{
      this.TrnMainObj.ProdList.forEach(x => {  x.FLATDISCOUNT = 0});
    }
    this.TrnMainObj.DCRATE = null;
    this.TrnMainObj.FLATDCAMNT = null;
    this.TrnMainObj.TOTALFLATDISCOUNT = 0;
    this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = 0;
    this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE = 0;
   
    this.ReCalculateBillWithNormal();

  }

  onFlatDiscountClear() {
    this.disableProd = true;
 
      this.TrnMainObj.ProdList.forEach(x => {  x.FLATDISCOUNT = 0});
    
    this.TrnMainObj.DCRATE = null;
    this.TrnMainObj.FLATDCAMNT = null;
    this.TrnMainObj.TOTALFLATDISCOUNT = 0;
    this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = 0;
    this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE = 0;
   
    this.ReCalculateBillWithNormal();

  }


  loadStockSettlement(VCHR) {
    this.masterService.LoadStockSettlement(VCHR).subscribe(
      data => {
        if (data.status === 'ok') {
          this.TrnMainObj = data.result;
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
            this.TrnMainObj.Mode = 'NEW';
            this.TrnMainObj.ProdList.forEach(element => {
              element.inputMode = true;
            });
          }

          for (let i in this.TrnMainObj.ProdList){
            //this.setAltunitDropDownForViewStock(i);
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].IsApproveStockSettlement = true;
            this.TrnMainObj.ProdList[i].MFGDATE =
              this.TrnMainObj.ProdList[i].MFGDATE == null
                ? ''
                : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(
                  0,
                  10
                );
            this.TrnMainObj.ProdList[i].EXPDATE =
              this.TrnMainObj.ProdList[i].EXPDATE == null
                ? ''
                : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(
                  0,
                  10
                );
          }
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.Warehouse = this.TrnMainObj.MWAREHOUSE;
          if (
            !this.Warehouse &&
            this.TrnMainObj.ProdList &&
            this.TrnMainObj.ProdList.length > 0
          ) {
            this.Warehouse = this.TrnMainObj.ProdList[0].WAREHOUSE;
          }
          this.trnmainBehavior.next(this.TrnMainObj);
        }
      },
      error => {
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => this.trnmainBehavior.complete()
    );
  }
  

  getItemType() {
    return [
      { label: 'FOC', value: 'FOC' },
      { label: 'FAULTY', value: 'FALUTY' }
    ];
  }

  getReceivedType() {
    return [
      { label: 'DAMAGED', value: 'DAMAGED' },
      { label: 'MISSING', value: 'MISSING' }
    ];
  }

  nullToZeroConverter(value) {

    if (
      value === undefined ||
      value == null ||
      value === null ||
      value === '' ||
      value === 'Infinity' ||
      value === 'NaN' ||
      value === NaN ||
      isNaN(parseFloat(value))
    ) {
      return 0;
    }
    return parseFloat(value);
  }

  CalculateNormal(
    TrnProdObj: TrnProd,
    ServiceTaxRate = this.setting.appSetting.ServiceTaxRate,
    VatRate = this.setting.appSetting.VATRate,
    calcuteDiscount = 0
  ) {
    TrnProdObj.AMOUNT = TrnProdObj.Quantity * TrnProdObj.RATE;
    if (this.setting.appSetting.ENABLEMULTICURRENCY === 1) {
      TrnProdObj.NCRATE = this.curencyConvert(TrnProdObj.RATE);
      TrnProdObj.EXRATE = this.TrnMainObj.EXRATE;
      TrnProdObj.AMOUNT = TrnProdObj.Quantity * TrnProdObj.NCRATE;
    }
    if (calcuteDiscount === 1) {
      if (TrnProdObj.IDIS != null) {
        if (TrnProdObj.IDIS.indexOf('%') < 0) {
          TrnProdObj.INDDISCOUNT = parseFloat(TrnProdObj.IDIS);
        } else {
          let dis = parseFloat(TrnProdObj.IDIS.replace('%', '').trim());
          dis = dis / 100;
          TrnProdObj.INDDISCOUNT = TrnProdObj.AMOUNT * dis;
        }
      }
    }
    TrnProdObj.DISCOUNT =
      this.nullToZeroConverter(TrnProdObj.INDDISCOUNT) +
      this.nullToZeroConverter(TrnProdObj.PROMOTION) +
      this.nullToZeroConverter(TrnProdObj.LOYALTY);
    if (this.nullToZeroConverter(TrnProdObj.ISSERVICECHARGE) === 1) {
      TrnProdObj.SERVICETAX =
        (TrnProdObj.AMOUNT - TrnProdObj.DISCOUNT) * ServiceTaxRate;
    }
    if (TrnProdObj.ISVAT === 1) {
      TrnProdObj.TAXABLE =
        TrnProdObj.AMOUNT -
        TrnProdObj.DISCOUNT +
        this.nullToZeroConverter(TrnProdObj.SERVICETAX);
      TrnProdObj.NONTAXABLE = 0;
      TrnProdObj.VAT = TrnProdObj.TAXABLE * VatRate;
      TrnProdObj.GSTRATE = VatRate;
    } else {
      TrnProdObj.TAXABLE = 0;
      TrnProdObj.NONTAXABLE =
        TrnProdObj.AMOUNT -
        TrnProdObj.DISCOUNT +
        this.nullToZeroConverter(TrnProdObj.SERVICETAX);
    }
    TrnProdObj.NETAMOUNT =
      TrnProdObj.TAXABLE + TrnProdObj.NONTAXABLE + TrnProdObj.VAT;
    return TrnProdObj;
  }

  FlatDiscountPercentChange() {
    
    this.TrnMainObj.INDDIS = 0; // IF Discount is given in percentage
    let checkIndDiscout = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.INDDISCOUNTRATE) > 0 || this.nullToZeroConverter(x.INDDISCOUNT) > 0)[0];
    
    if (checkIndDiscout != null && checkIndDiscout) {
      if (confirm("Are you sure you want ko give individual discount")) {
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales
          || (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote && this.TrnMainObj.DOMANUALSALESRETURN == 1)
        ) {
          this.TrnMainObj.ProdList.forEach(x => { x.INDDISCOUNTRATE = this.nullToZeroConverter(this.TrnMainObj.DCRATE), x.CRATE = (x.RATE - (x.RATE * this.nullToZeroConverter(this.TrnMainObj.DCRATE) / 100)) });
          this.ReCalculateBillWithNormal();
          
        } else {
          
          this.flatDiscountAssign('percent');
        }
      }
    } else {
      if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales
        || (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote && this.TrnMainObj.DOMANUALSALESRETURN == 1)
      ) {
        this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = this.TrnMainObj.DCRATE)
        this.ReCalculateBillWithNormal();
        
      } else {
        this.flatDiscountAssign('percent');
      }
    }

  }


  calculateNetRATE(i) {

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
      (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")

    ) {

      if (this.TrnMainObj.ProdList[i].ALTUNIT == this.TrnMainObj.ProdList[i].BASEUNIT) {

        this.TrnMainObj.ProdList[i].NETRATE =
          ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY)) -
            ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY)) *
              this.nullToZeroConverter(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].INDDISCOUNTRATE) / 100)));

      } else {
        this.TrnMainObj.ProdList[i].NETRATE =
          ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[i].ALTCONFACTOR))) -
            ((this.TrnMainObj.ProdList[i].RATE + (this.nullToZeroConverter(this.TrnMainObj.ProdList[i].ECSAMOUNT) / (this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[i].ALTCONFACTOR))) *
              this.nullToZeroConverter(this.nullToZeroConverter(this.TrnMainObj.ProdList[i].INDDISCOUNTRATE) / 100)));

      }
      this.TrnMainObj.ProdList[i].CRATE = this.TrnMainObj.ProdList[i].NETRATE;

    }
  }

  FlatDiscountAmountChange() {
    console.log("FLAT DISCOUNT AMOUNT CHANGE");
    let disLimit = this.masterService.userProfile.roleRights.find(x=> x.right == 'DISLIMIT')?.value;
    // console.log("dislimit", disLimit);
    this.TrnMainObj.INDDIS = 1;// If discount is given in AMOUNT
    this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE = 0;
    let checkIndDiscout = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.INDDISCOUNTRATE) > 0 || this.nullToZeroConverter(x.INDDISCOUNT) > 0)[0];
    // console.log("flat dicosunt amount chnage", checkIndDiscout);
    if (checkIndDiscout != null && checkIndDiscout ) {
      if (confirm("Are you sure you want to give individual discount")) {
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder
        ) {
          this.TrnMainObj.ProdList.forEach(x => {
            x.INDDISCOUNT = 0;
            x.INDDISCOUNTRATE = 0;        
          });

          let totalGrossAmount =0;
          this.TrnMainObj.ProdList.forEach(x => totalGrossAmount += x.ALTERNATEQUANTIY * x.RATE);
          console.log("flat disc amount ,",this.TrnMainObj.TOTALFLATDISCOUNT, "totlagrossamount", totalGrossAmount );
          if(this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) > 0){
            this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = (this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) * 100) / totalGrossAmount );
            this.ReCalculateBillWithNormal();
          }

          this.ReCalculateBillWithNormal();
        } 
        else if(this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice 
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice 
          || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales
          || (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote && this.TrnMainObj.DOMANUALSALESRETURN == 1) ){
            let grossamnt = 0;
            this.TrnMainObj.ProdList.filter(x => x.DISMODE != 'Non-Discountable').forEach(
              x => {
                grossamnt += x.AMOUNT;
              }
            );
            let flatDiscPerc = this.nullToZeroConverter((this.TrnMainObj.FLATDCAMNT * 100) / grossamnt );
            console.log("flatDISCPERC", this.nullToZeroConverter(flatDiscPerc));
          
            if(flatDiscPerc > disLimit){
              this.alertService.warning("Discount Limit:"+disLimit + "% exceeded, with given discount amount: "+ this.TrnMainObj.FLATDCAMNT);
             
            }
          this.calculateFixedDiscount();
    
        }
        else {
          this.flatDiscountAssign('amount');
        }
       
      }
    } else {
      if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || 
        this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder  || 
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || 
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder)  {
      
          let totalGrossAmount =0;
          this.TrnMainObj.ProdList.forEach(x => totalGrossAmount += x.AMOUNT);
          console.log("flat disc amount ,",this.TrnMainObj.TOTALFLATDISCOUNT, "totlagrossamount", totalGrossAmount );
          if(this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) > 0){
            this.TrnMainObj.ProdList.forEach(x => x.INDDISCOUNTRATE = (this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT) * 100) / totalGrossAmount );
            this.ReCalculateBillWithNormal();
          }
      }
      else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice 
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice 
        || this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales
        || (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote && this.TrnMainObj.DOMANUALSALESRETURN == 1) ) {
        let grossamnt = 0;
        this.TrnMainObj.ProdList.filter(x => x.DISMODE != 'Non-Discountable').forEach(
          x => {
            grossamnt += (this.nullToZeroConverter(x.ALTERNATEQUANTIY)*this.nullToZeroConverter(x.RATE))+this.nullToZeroConverter(x.ECSAMOUNT);
          }
        );
        let flatDiscPerc = this.nullToZeroConverter((this.TrnMainObj.FLATDCAMNT * 100) / grossamnt );
        console.log("flatDISCPERC", this.nullToZeroConverter(flatDiscPerc));
       
        if(flatDiscPerc > disLimit){
          this.alertService.warning("Discount Limit:"+disLimit + "% exceeded, with given discount amount: "+ this.TrnMainObj.FLATDCAMNT);
          
        }

        this.calculateFixedDiscount();
      }
      else {
        this.flatDiscountAssign('amount');
      }
    }
  }


  calculateFixedDiscount(){
    let grossAmount = 0;
    this.TrnMainObj.ProdList.filter(x => x.DISMODE != 'Non-Discountable').forEach(
      x => {
        grossAmount += (this.nullToZeroConverter(x.ALTERNATEQUANTIY)*this.nullToZeroConverter(x.RATE))+this.nullToZeroConverter(x.ECSAMOUNT);
      }
    )
    console.log("gross Amount in calculateFixedDiscount", grossAmount)
    let flatDiscountPercent = (this.nullToZeroConverter(this.TrnMainObj.FLATDCAMNT) / (grossAmount / 100));
    this.TrnMainObj.ProdList.filter(x => x.DISMODE != 'Non-Discountable').forEach(x => x.INDDISCOUNTRATE = flatDiscountPercent);
    this.ReCalculateBillWithNormal();
  }


  calculateNetRateInFamount(){
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
      

      for (const iterator of this.TrnMainObj.ProdList) {
        console.log("INDDISCOUNR",iterator.INDDISCOUNT,"flatdiscount",iterator.FLATDISCOUNT, "BEFOREFLATDISC", this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE, "QTY", iterator.Quantity);
              if (iterator.UNIT == iterator.BASEUNIT) {
        iterator.NETRATE =
          (iterator.RATE +
            (this.nullToZeroConverter(iterator.ECSAMOUNT) /
              iterator.Quantity) -
            this.nullToZeroConverter(iterator.INDDISCOUNT/iterator.Quantity) - 
            this.nullToZeroConverter(iterator.FLATDISCOUNT/iterator.Quantity) 
            // this.nullToZeroConverter(iterator.RATE * (this.TrnMainObj.AdditionalObj.AdditionalObj.BEFOREFLATDISCRATE/100))
              ) ;
          
            // if(this.TrnMainObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AFTERFLATDISCRATE > 0){
            //   iterator.NETRATE = iterator.NETRATE- (this.nullToZeroConverter(iterator.NETRATE * (this.TrnMainObj.AFTERFLATDISCRATE/100)));
            // }
        iterator.CRATE = iterator.NETRATE;

      } else {
        iterator.NETRATE =
          (iterator.RATE +
            (this.nullToZeroConverter(iterator.ECSAMOUNT) /
              iterator.Quantity) -
            this.nullToZeroConverter(iterator.INDDISCOUNT/iterator.Quantity) - 
            this.nullToZeroConverter(iterator.FLATDISCOUNT/iterator.Quantity) 
            );

            // if(this.TrnMainObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AFTERFLATDISCRATE > 0){
            //   iterator.NETRATE = iterator.NETRATE- (this.nullToZeroConverter(iterator.NETRATE * (this.TrnMainObj.AFTERFLATDISCRATE/100)));
            // }

        iterator.CRATE = iterator.NETRATE;
      }       

      }

    }

  }


  flatDiscountAssign(changeType) {
    if (
      changeType === 'percent' &&
      (this.TrnMainObj.DCRATE < 0 || this.TrnMainObj.DCRATE > 100)
    ) {
      this.TrnMainObj.DCRATE = 0;
      alert('Invalid Flat Discount Percent');
      return;
    }
    if (changeType === 'amount') {
      this.TrnMainObj.DCRATE = 0;
    } else {
      let totalAmountWithIndDiscount = this.TrnMainObj.ProdList.reduce(
        (sum, x) =>
          sum +
          (this.nullToZeroConverter(x.AMOUNT) -
            this.nullToZeroConverter(x.INDDISCOUNT)),
        0
      );
      this.TrnMainObj.TOTALFLATDISCOUNT =
        (this.TrnMainObj.DCRATE * totalAmountWithIndDiscount) / 100;
    }
    this.ReCalculateBillWithNormal();
  }

  curencyConvert(rate) {
    let C = this.masterService.Currencies.find(
      x => x.CURNAME === this.TrnMainObj.FCurrency
    );
    if (C != null) {
      return C.EXRATE * rate;
    } else return rate;
  }

  ischeckReturnQuantityError(prod: TrnProd): boolean {
    try {
      let pr = this.cnReturnedProdList.find(itm => itm.MCODE === prod.MCODE);
      if (!pr) {
        // there is no such product in return list
        return true;
      }
      let prodarray: TrnProd[] = [];
      this.cnReturnedProdList.forEach(prd => {
        let p: TrnProd;

        if (prodarray.length > 0) {
          p = prodarray.find(res => res.MCODE === prd.MCODE);
        }
        if (p) {
          p.Quantity += prd.Quantity;
          p.REALQTY_IN += prd.REALQTY_IN;
          if (prd.RATE > p.RATE) {
            p.RATE = prd.RATE;
          }
        } else {
          prodarray.push(prd);
        }
      });
      let tProd = prodarray.find(prd => prd.MCODE === prod.MCODE);
      if (tProd) {
        if (prod.Quantity > tProd.Quantity) {
          return true;
        }
        if (prod.RATE > tProd.RATE) {
          return true;
        }
      }

      return false;
    } catch (ex) {
    }
  }

  resetData() {
    this.TrnMainObj.TRNAC = null;
    this.TrnMainObj.PARAC = null;
    this.TrnMainObj.BILLTO = '';
    this.TrnMainObj.BILLTOADD = '';
    this.TrnMainObj.BILLTOMOB = '';
    this.TrnMainObj.BILLTOTEL = '';
    //  this.TrnMainObj.ProdList = [];
  }
  a = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen '
  ];
  b = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];

  decimalToWord(frac) {
    if (parseInt(frac[1]) !== 0) {
      return (
        (this.a[Number(frac[1])] ||
          this.b[frac[1][0]] + ' ' + this.a[frac[1][1]]) + 'Paisa Only '
      );
    } else {
      return '';
    }
  }

  digitToWord(num) {
    if (num != null && num !== '' && num !== undefined) {
      let nums = num.toString().split('.');
      let whole = nums[0];
      let w = ('000000000' + whole)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if ((whole = whole.toString()).length > 9) return 'Overflow';
      if (nums.length === 2) {
        let wholeFraction = nums[1];
        let fraction = wholeFraction.substring(0, 2);
        if (
          fraction === '1' ||
          fraction === '2' ||
          fraction === '3' ||
          fraction === '4' ||
          fraction === '5' ||
          fraction === '6' ||
          fraction === '7' ||
          fraction === '8' ||
          fraction === '9'
        ) {
          fraction = fraction + '0';
        } else if (
          fraction === '01' ||
          fraction === '02' ||
          fraction === '03' ||
          fraction === '04' ||
          fraction === '05' ||
          fraction === '06' ||
          fraction === '07' ||
          fraction === '08' ||
          fraction === '09'
        ) {
          fraction = '09';
          fraction = fraction.substring(1, 2);
        }
        let f = ('00' + fraction).substr(-2).match(/^(\d{2})$/);

        if (!w || !f) return;
        let str = '';
        str +=
          parseInt(w[1]) !== 0
            ? (this.a[Number(w[1])] ||
              this.b[w[1][0]] + ' ' + this.a[w[1][1]]) + 'Crore '
            : '';
        str +=
          parseInt(w[2]) !== 0
            ? (this.a[Number(w[2])] ||
              this.b[w[2][0]] + ' ' + this.a[w[2][1]]) + 'Lakh '
            : '';
        str +=
          parseInt(w[3]) !== 0
            ? (this.a[Number(w[3])] ||
              this.b[w[3][0]] + ' ' + this.a[w[3][1]]) + 'Thousand '
            : '';
        str +=
          parseInt(w[4]) !== 0
            ? (this.a[Number(w[4])] ||
              this.b[w[4][0]] + ' ' + this.a[w[4][1]]) + 'Hundred '
            : '';
        str +=
          parseInt(w[5]) !== 0
            ? (this.a[Number(w[5])] ||
              this.b[w[5][0]] + ' ' + this.a[w[5][1]]) +
            'and ' +
            this.decimalToWord(f)
            : '';
        return str;
      } else {
        if (!w) return;
        let str = '';
        str +=
          parseInt(w[1]) !== 0
            ? (this.a[Number(w[1])] ||
              this.b[w[1][0]] + ' ' + this.a[w[1][1]]) + 'Crore '
            : '';
        str +=
          parseInt(w[2]) !== 0
            ? (this.a[Number(w[2])] ||
              this.b[w[2][0]] + ' ' + this.a[w[2][1]]) + 'Lakh '
            : '';
        str +=
          parseInt(w[3]) !== 0
            ? (this.a[Number(w[3])] ||
              this.b[w[3][0]] + ' ' + this.a[w[3][1]]) + 'Thousand '
            : '';
        str +=
          parseInt(w[4]) !== 0
            ? (this.a[Number(w[4])] ||
              this.b[w[4][0]] + ' ' + this.a[w[4][1]]) + 'Hundred '
            : '';
        str +=
          parseInt(w[5]) !== 0
            ? (this.a[Number(w[5])] ||
              this.b[w[5][0]] + ' ' + this.a[w[5][1]]) + 'Only '
            : '';
        return str;
      }
    }
  }

  setDefaultValueInTransaction() {
    if (this.TrnMainObj.MWAREHOUSE == null || this.TrnMainObj.MWAREHOUSE === '') { this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse; }

    this.TrnMainObj.BRANCH = this.userProfile.userBranch;
    this.TrnMainObj.DIVISION = this.userProfile.userDivision;


    this.TrnMainObj.ProdList.forEach(prod => {
      prod.WAREHOUSE = this.TrnMainObj.MWAREHOUSE;
      prod.BRANCH = this.TrnMainObj.BRANCH;
      //   prod.Supplier=this.TrnMainObj.PARAC;
    });
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
      console.log("trnMocde", this.settlementList, this.TrnMainObj.TRNMODE);

      let settlementmode = this.settlementList.filter(
        x => x.DESCRIPTION === this.TrnMainObj.TRNMODE
      )[0];
      if (settlementmode == null) {
        this.alertService.warning('Settlement Mode not found.');
        return false;
      }
      for (let i of this.TrnMainObj.ProdList) {
        if (settlementmode.DECREASE === 0) {
          i.REALQTY_IN = i.Quantity;
          i.ALTQTY_IN = i.Quantity;
          i.RealQty = 0;
          i.AltQty = 0;
        } else if (settlementmode.DECREASE === 1) {
          i.REALQTY_IN = 0;
          i.ALTQTY_IN = 0;
          i.RealQty = i.Quantity;
          i.AltQty = i.Quantity;
        }
      }
      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0)[0];
      if (StockVAlidateItem != null) {

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }
    } else if (
      
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut
    ) {
     this.TrnMainObj.BILLTO = this.TrnMainObj.DIVISION;
    }
    return true;
  }

 checkBatchWisePriceSelectedorNot(){
    var priceSelctionCheckObj = this.TrnMainObj.ProdList.filter(x => x.HasMulitplePrice==1)[0];
    if(priceSelctionCheckObj){
      if(!priceSelctionCheckObj.PriceSelected){
         this.alertService.warning(`Please select the batch wise price for item :${priceSelctionCheckObj.ITEMDESC}`);
         return false;
      }
    } 
    return true;
 }


  checkTransactionValidation() {

    let voucherType = this.TrnMainObj.VoucherType;


    if(this.userSetting.EnableBatch ==2){
      if(voucherType in BatchRequireEnum){
         let nullBatchObj = this.TrnMainObj.ProdList.filter(x => this.nullToZeroConverter(x.EnableAutoBatch) == 0)
                                                    .find( x => x.BATCH == null || x.BATCH == "" ||  x.BATCH == undefined || x.BATCH.trim() == "");
          if(nullBatchObj){
             this.alertService.info(`Empty batch detected for item ${nullBatchObj.ITEMDESC}`);
             return false;
          }
      }
    }



    if (
      this.TrnMainObj.Mode === 'VIEW' &&
      this.TrnMainObj.VoucherType !== VoucherTypeEnum.TaxInvoiceCancel
    ) {
      alert('Can Not save View Voucher');
      return false;
    }
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue) {
      if (
        this.TrnMainObj.BILLTO == null ||
        this.TrnMainObj.BILLTO === undefined ||
        this.TrnMainObj.BILLTO === ''
      ) {
        alert('Warehouse From field is not detected.');
        return false;
      }
      if (
        this.TrnMainObj.BILLTOADD == null ||
        this.TrnMainObj.BILLTOADD === undefined ||
        this.TrnMainObj.BILLTOADD === ''
      ) {
        alert('Warehouse To field is not detected.');
        return false;
      }
      if (this.TrnMainObj.BILLTOADD === this.TrnMainObj.BILLTO) {
        alert('You cannot transfer to same Warehouse');
        return false;
      }
      let minimumStockValidation = this.TrnMainObj.ProdList.filter(x => x.Quantity > x.MINLEVEL && x.MINLEVEL > 0)[0];
      
      if (minimumStockValidation != null) {
        this.alertService.error("Quantity cannot exceed the minimum stock level");
        return false;
      }
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice
    ) {
      // if (this.TrnMainObj.PTYPE != 6 && (this.TrnMainObj.TRNAC == null || this.TrnMainObj.TRNAC == "")) {
      //   alert("Please select Transaction account");
      //   return false;
      // }l
      this.checkDeuplicateBarcode();
      if (this.duplicateBarCodeMessage === 'duplicateBarcode') {
        this.alertService.info('duplicate Barcode with item is detected');
        return false;
      }


    }

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue  || this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
      let checkDateSetingObj = this.TrnMainObj.ProdList.filter(x => x.REQEXPDATE == 1 && ( this.nullToZeroConverter(x.EXPDATE) == 0))[0];
      let AllProductDetails = [];

      if (checkDateSetingObj != null && this.userSetting.ENABLESINGLEBATCHMODE != 1) {
        this.alertService.info(`Please enter MFD date and EXP date on item:${checkDateSetingObj.ITEMDESC}`);
        return false;
      }

      this.TrnMainObj.ProdList.forEach(x => {
        if (x.PTYPE === 6) {
          AllProductDetails = AllProductDetails.concat(x.ProductDetails);
        }
      })





      if (AllProductDetails.length >= 1) {
        var seen = {};
        var hasDuplicate = AllProductDetails.some(x => {
          if (seen.hasOwnProperty(x.IEMI1)) {
            this.alertService.info("Duplicate IME1 is detected on Item:" + x.MCODE + "");
            return;
          }
        })
      }

    }

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
      if (this.userSetting.EnableRefno === 1) {
        if (this.TrnMainObj.CHALANNO === '' || this.TrnMainObj.CHALANNO == null) {
          this.alertService.info('Chalan No Could not be Null');
          return false;
        }
      }

    }

    if(this.TrnMainObj.VoucherType=== VoucherTypeEnum.Repack){

      if(!this.allowNegativeBilling(0,true)){
        let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.ROUTQTY > x.STOCK && x.STOCK > 0)[0];
        if (StockVAlidateItem != null &&
          this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {
  
          this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
          return false;
        }
      }



    }

    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote && this.userSetting.ENABLESINGLEBATCHMODE == 0 ){
      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.Quantity > x.STOCK)[0];
      if (StockVAlidateItem != null &&
        this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {

        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }
    }

    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut){
      console.log("new",this.allowNegativeBilling(0,true));
      if(!this.allowNegativeBilling(0,true)){
        console.log("new1",this.allowNegativeBilling(0,true));

      let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0  && x.PTYPE ==0 )[0];
      if (StockVAlidateItem != null ) {
          
        this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
        return false;
      }
    }

    }

    if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived
    ) {

      if (
        this.TrnMainObj.ProdList.some(
          x => new Date(x.MFGDATE) > new Date() && x.MCODE != null &&
            this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        )
      ) {
        this.alertService.warning(
          'Invalid Manufacture Date Item Detected..Please Review the list...'
        );
        return false;
      }



      let invalidQtyObj = this.TrnMainObj.ProdList.filter(x => x.Quantity == 0)[0];
      if (invalidQtyObj != null) {
        this.alertService.info(`Qty Is Not Specified For The Item ${invalidQtyObj.ITEMDESC} (${invalidQtyObj.MENUCODE}) Pls, Check Your Entry.`);
        return false;
      }

      

      if(!this.allowNegativeBilling(0,true)){
        let StockVAlidateItem = this.TrnMainObj.ProdList.filter(x => x.RealQty > x.STOCK && x.STOCK > 0 && x.PTYPE ==0 )[0];
        if (StockVAlidateItem != null &&
          this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6 && x.PTYPE !== 9)) { //stock not checked for perishable item
  
          this.alertService.error('Inserted Quantity is greater than its Stock on item :' + StockVAlidateItem.ITEMDESC);
          return false;
        }
  
      }




    }
    return true;
  }





  checkDeuplicateBarcode() {
    console.log('batlist', this.TrnMainObj.ProdList);
    let valuesArray = this.TrnMainObj.ProdList.map(x => { return x.BATCH; });
    let duplicate = valuesArray.some((item, x) => {
      return valuesArray.indexOf(item) !== x;
    });
    console.log('duplicatevalue', duplicate);
  }



  initialFormLoad(voucherType) {

    this.TrnMainObj = <TrnMain>{};
    this.masterService.productDetailsListHold = [];
    this.showPerformaApproveReject = false;
    this.TrnMainObj.AdditionalObj = <TrnMain_AdditionalInfo>{};
    this.TrnMainObj.TransporterEway = <Transporter_Eway>{};
    this.TrnMainObj.IMPORTDETAILS = <IMPORT_DETAILS>{};

    // this.TrnMainObj.TrntranList = [];
    this.TrnMainObj.guid = uuid();
    this.TrnMainObj.Mode = 'NEW';
    this.TrnMainObj.DIVISION = this.userProfile.userDivision;
    if(this.arouter.snapshot.url[0]?.path === 'self-billing'){
      this.TrnMainObj.MWAREHOUSE = '';
      }
    else{
      this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
    }
    
    console.log('phiscalid',this.masterService.PhiscalObj);

    this.TrnMainObj.PhiscalID = this.masterService.PhiscalObj.PhiscalID;
    console.log('invchtyp',voucherType,VoucherTypeEnum.StockIssueReturn);
    switch (voucherType) {
      case VoucherTypeEnum.PurchaseOrder:
        this.TrnMainObj.VoucherPrefix = 'PO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PurchaseOrder;
        if(this.urlpath == "purchase-import-order"){
          this.TrnMainObj.CNDN_MODE = 10;
          this.pageHeading = "IMPORT PURCHASE ORDER";
          this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE_ORDER";
        }else{
          this.TrnMainObj.CNDN_MODE = 0;
          this.pageHeading = 'PURCHASE ORDER';
        }
        break;
      case VoucherTypeEnum.Purchase:
        this.TrnMainObj.VoucherPrefix = 'PI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
        if (this.urlpath == "purchase-import") {
          this.TrnMainObj.CNDN_MODE = 10;
          this.pageHeading = "IMPORT PURCHASE";
          this.TrnMainObj.AdditionalObj.tag = "IMPORT_PURCHASE";
        } else {
          this.TrnMainObj.CNDN_MODE = 0;
          this.pageHeading = 'PURCHASE INVOICE';
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.AdditionalObj.tag = "LOCAL_PURCHASE";
        }


        break;
      

      // case VoucherTypeEnum.DebitNote:
      //   this.TrnMainObj.VoucherPrefix = "DN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
      //   this.pageHeading = "Debit Note";
      //   break;
      // case VoucherTypeEnum.DebitNote:
      //   this.TrnMainObj.VoucherPrefix = "DN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
      //   this.pageHeading = "Debit Note";
      //   break;
   

      case VoucherTypeEnum.SalesOrder:
        this.TrnMainObj.VoucherPrefix = 'SO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.SalesOrder;
        this.voucherNoHeader = 'SO No';
        this.pageHeading = 'SALES ORDER';
        break;
      case VoucherTypeEnum.Sales:
        this.TrnMainObj.VoucherPrefix = 'SI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Sales;
        this.pageHeading = 'SALES INVOICE';
         if(this.arouter.snapshot.url[0].path === 'add-membership-sales-invoice'){
          this.pageHeading = 'ABBREVIATED-MEMBERSHIP SALES INVOICE'
          this.TrnMainObj.AdditionalObj.tag = "MembershipSalesInvoice";
        }
        break;
      case VoucherTypeEnum.TaxInvoice:
        this.TrnMainObj.VoucherPrefix = 'TI';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoice;
        this.TrnMainObj.TRNMODE = this.userSetting.GblEnableMixedTenderMode == 1 ? 'cash': 'credit';
        console.log("activeurl", this.arouter.snapshot.url[0].path);
        if (this.arouter.snapshot.url[0].path === 'self-billing') {
          this.pageHeading = 'SELF BILLING';
          this.TrnMainObj.TRNMODE = 'credit';
        } else {
          this.pageHeading = 'TAX INVOICE'
        }
        if (this.userProfile.CompanyInfo.ORG_TYPE === 'retailer' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'ak' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'ck' ||
          this.userProfile.CompanyInfo.ORG_TYPE.toLowerCase() === 'pms') {
          this.masterService.getCurrentDate().subscribe(
            date => {
              this.TrnMainObj.DeliveryDate = date.Date.substring(0, 10);
            },
            error => {
              this.masterService.resolveError(
                error,
                'voucher-date - getCurrentDate'
              );
            }
          );
        }
        break;
      // case VoucherTypeEnum.CreditNote:
      //   this.TrnMainObj.VoucherPrefix = "CN";
      //   this.TrnMainObj.VoucherType = VoucherTypeEnum.CreditNote;
      //   this.pageHeading = "Credit Note";
      //   break;
      case VoucherTypeEnum.StockIssue:
        this.TrnMainObj.VoucherPrefix = 'IS';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssue;
        if (this.arouter.snapshot.url[0].path === 'stock-issue-approval') {
          this.pageHeading = 'STOCK ISSUE APPROVAL';
        }else{
          this.pageHeading = 'STOCK ISSUE';
        }
        break;
        case VoucherTypeEnum.Stockadjustment:
          this.TrnMainObj.VoucherPrefix = 'SA';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Stockadjustment;
          this.TrnMainObj.VoucherAbbName = 'SA';
            this.pageHeading = 'STOCK ADJUSTMENT';
          break;
          case VoucherTypeEnum.BatchAdjustment:
            this.TrnMainObj.VoucherPrefix = 'BA';
            this.TrnMainObj.VoucherType = VoucherTypeEnum.BatchAdjustment;
              this.pageHeading = 'BATCH ADJUSTMENT';
            break;
      case VoucherTypeEnum.StockSettlement:
        this.TrnMainObj.VoucherPrefix = 'DM';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.StockSettlement;
        this.pageHeading = 'STOCK SETTLEMENT';
        break;
      case VoucherTypeEnum.OpeningStockBalance:
        this.TrnMainObj.VoucherPrefix = 'OP';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.OpeningStockBalance;
        this.pageHeading = 'OPENING STOCK ENTRY';
        break;
      case VoucherTypeEnum.BranchTransferIn:
        this.TrnMainObj.VoucherPrefix = 'TR';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.BranchTransferIn;
        this.pageHeading = 'BRANCH TRANSFER IN';
        this.TrnMainObj.VoucherAbbName = 'TR';
        break;
        case VoucherTypeEnum.Repack:
          this.TrnMainObj.VoucherPrefix = 'RP';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Repack;
          this.pageHeading = 'REPACK';
          this.TrnMainObj.VoucherAbbName = 'RP';
          break;  
      case VoucherTypeEnum.BranchTransferOut:
        this.TrnMainObj.VoucherPrefix = 'TO';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.BranchTransferOut;
        this.TrnMainObj.VoucherAbbName = 'TO';
        if (this.arouter.snapshot.url[0].path === 'transfer-out-approval') {
          this.pageHeading = 'BRANCH TRANSFER OUT APPROVAL'
        }else{
          this.pageHeading = 'BRANCH TRANSFER OUT';
        }
        break;
      case VoucherTypeEnum.RequisitionEntry:
          this.TrnMainObj.VoucherPrefix = 'RQ';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.RequisitionEntry;
          this.TrnMainObj.VoucherAbbName = 'RQ';
          this.pageHeading = 'REQUISITION ENTRY';
          this.TrnMainObj.INVOICETYPE = 'TransferOut';
          console.log("reqentry");
          break;  
      case VoucherTypeEnum.RequisitionApproval:
          this.TrnMainObj.VoucherPrefix = 'RA';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.RequisitionApproval;
          this.TrnMainObj.VoucherAbbName = 'RA';
          this.pageHeading = 'REQUISITION APPROVAL';
          break;  
      case VoucherTypeEnum.PerformaSalesInvoice:
        this.TrnMainObj.VoucherPrefix = 'PP';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PerformaSalesInvoice;
        this.pageHeading = 'PROFORMA INVOICE';
        break;
      case VoucherTypeEnum.PurchaseOrderCancel:
        this.TrnMainObj.VoucherPrefix = 'PC';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PurchaseOrderCancel;
        this.pageHeading = 'PO CANCEL';
        break;
      // for account vouchers
      case VoucherTypeEnum.Journal:
        this.TrnMainObj.VoucherPrefix = 'JV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Journal;
        this.TrnMainObj.TrntranList = [];
        this.pageHeading = 'JOURNAL VOUCHER';
        this.TableAcHeader = 'Acount (A/C)';
        this.addRowForTransaction(-1);
        break;
      case VoucherTypeEnum.PaymentVoucher:
        this.TrnMainObj.VoucherPrefix = 'PV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PaymentVoucher;
        this.TrnMainObj.TrntranList = [];
        this.pageHeading = 'EXPENSES VOUCHER ';
        this.TableAcHeader = 'Party/Expenses A/C';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.ReceiveVoucher:
        this.TrnMainObj.VoucherPrefix = 'RV';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ReceiveVoucher;
        this.pageHeading = 'INCOME VOUCHER';
        this.TrnMainObj.TrntranList = [];
        this.TableAcHeader = 'Party/Income A/C';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.DebitNote:
        this.TrnMainObj.VoucherPrefix = 'DN';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
        this.TrnMainObj.VoucherAbbName = 'DN';
        this.pageHeading = 'DEBIT NOTE';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.CreditNote:
        this.TrnMainObj.VoucherPrefix = 'CN';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.CreditNote;
        this.pageHeading = 'CREDIT NOTE';
        this.voucherNoHeader = 'Return No';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        this.TrnMainObj.DOFULLRETURN = this.DOFULLRETURN = this.userSetting.REFBILLCOMPULSORINCNOTE;
        this.TrnMainObj.TRNMODE = 'credit';
        this.TrnMainObj.DOMANUALSALESRETURN = 0;

        break;

      case VoucherTypeEnum.ContraVoucher:
        this.TrnMainObj.VoucherPrefix = 'CE';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ContraVoucher;
        this.pageHeading = 'CONTRA VOUCHER';
        this.TrnMainObj.TrntranList = [];
        this.addRowForTransaction(-1);
        break;

      // configuration
      case VoucherTypeEnum.AccountOpeningBalance:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.AccountOpeningBalance;
        this.pageHeading = 'ACCOUNT OPENING BALANCE';
        this.TrnMainObj.TrntranList = [];
        this.TrnMainObj.VoucherPrefix = 'OB';
        this.TrnMainObj.VoucherAbbName = 'OB';
        this.TrnMainObj.VoucherName = 'OBBILL';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.PartyOpeningBalance:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.PartyOpeningBalance;
        this.pageHeading = 'PARTY OPENING BALANCE';
        this.TrnMainObj.TrntranList = [];
        this.TrnMainObj.VoucherPrefix = 'AO';
        this.TrnMainObj.VoucherAbbName = 'AO';
        this.TrnMainObj.VoucherName = 'OPPARTYBILL';
        this.addRowForTransaction(-1);
        break;

      case VoucherTypeEnum.TaxInvoiceCancel:
        this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoiceCancel;
        this.pageHeading = 'BILL CANCEL';
        this.TrnMainObj.VoucherPrefix = 'SC';
        this.TrnMainObj.VoucherAbbName = 'SC';
        break;
      case VoucherTypeEnum.Quotation:
        this.TrnMainObj.VoucherPrefix = 'QT';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.Quotation;
        this.pageHeading = 'QUOTATION';
        break;
      case VoucherTypeEnum.GoodsReceived:
        this.TrnMainObj.VoucherPrefix = 'GR';
        this.TrnMainObj.VoucherType = VoucherTypeEnum.GoodsReceived;
        this.pageHeading = 'GOODS RECEIVED NOTE';
        this.TrnMainObj.TRNMODE = 'credit';
        // this.TrnMainObj.AdditionalObj.tag = "LOCAL_PURCHASE";
        break;
      case VoucherTypeEnum.StockIssueReturn:
          this.TrnMainObj.VoucherPrefix = 'IR';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssueReturn;
          this.TrnMainObj.VoucherAbbName = 'IR';   
          this.pageHeading = 'STOCK ISSUE RETURN';
          break;  
      case VoucherTypeEnum.ProductionEntry:
        this.TrnMainObj.VoucherPrefix = this.productionEntryPrefix;
        this.TrnMainObj.VoucherType = VoucherTypeEnum.ProductionEntry;
        this.pageHeading = 'ITEM UNIT CONVERSION';
        this.TrnMainObj.VoucherAbbName =this.productionEntryPrefix;
        this.TrnMainObj.AdditionalObj.RETURNTYPE = "production";
      
        this.addConsumptionRow();
        break;

        
    }

    console.log('ll',this.TrnMainObj);

    this.getVoucherNumber();

    this.getCurrentDate();

    if (this.TrnMainObj.VoucherType === 58)
      return;

    this.addRow();
    this.masterService.isAddFormShown_1 = false;
  }


  getVoucherNumber() {
    if (this.arouter.snapshot.url[0]?.path === 'transfer-out-approval' || this.arouter.snapshot.url[0]?.path === 'stock-issue-approval') {
      return;
    }

    this.masterService.getVoucherNo(this.TrnMainObj).subscribe(res => {
      if (res.status === 'ok') {
        console.log("get VCHR NO",  res.result );
        let TMain = <TrnMain>res.result;
        this.TrnMainObj.VCHRNO = TMain.VCHRNO;
        if(TMain.VoucherPrefix != 'QT'){
        // this.TrnMainObj.CHALANNO = TMain.CHALANNO;
        }
        this.TrnMainObj.PhiscalID = TMain.PhiscalID;
        this.TrnMainObj.PARAC = TMain.PARAC;
        // this.disableOnGRNload =false;
      } else {
        alert('Failed to retrieve VoucherNo');
      }
    });
  }

  getVoucherNumberForTaxCancel() {
    this.masterService.getVoucherNo(this.TrnMainObj).subscribe(res => {
      if (res.status === 'ok') {
        let TMain = <TrnMain>res.result;
        this.TrnMainObj.CHALANNO = TMain.CHALANNO;
        this.TrnMainObj.PhiscalID = TMain.PhiscalID;
        this.TrnMainObj.PARAC = TMain.PARAC;
      } else {
        alert('Failed to retrieve VoucherNo');
      }
    });
  }

  getCurrentDate() {
    this.masterService.getCurrentDate().subscribe(
      date => {
        this.TrnMainObj.TRNDATE = date.Date.substring(0, 10);
        this.TrnMainObj.TRN_DATE = date.Date.substring(0, 10);
        this.TrnMainObj.BSDATE = this.getBsDate(this.TrnMainObj.TRNDATE);
        this.TrnMainObj.BS_DATE = this.getBsDate(this.TrnMainObj.TRN_DATE);
        console.log("bd date", this.TrnMainObj.BSDATE, this.TrnMainObj.BS_DATE);
        
        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance){ 
          this.TrnMainObj.TRNDATE = this.userProfile.CompanyInfo.FBDATE.split('T')[0];
          this.TrnMainObj.BSDATE = this.userProfile.CompanyInfo.FBDATE_BS;
        }
      },
      error => {
        this.masterService.resolveError(error, 'voucher-date - getCurrentDate');
      }
    );
  }

   getBsDate(date) {
    let adbs = require('ad-bs-converter');
    console.log("CHANGE TO BS YOYOYO", date);
    let adDate = (date.replace('-', '/')).replace('-', '/');
    let bsDate = adbs.ad2bs(adDate);
    return ( (bsDate.en.day == '1' || bsDate.en.day == '2' || bsDate.en.day == '3' || bsDate.en.day == '4' || bsDate.en.day == '5' || bsDate.en.day == '6' || bsDate.en.day == '7' || bsDate.en.day == '8' || bsDate.en.day == '9' ? '0' + bsDate.en.day : bsDate.en.day) + '/'+ (bsDate.en.month == '1' || bsDate.en.month == '2' || bsDate.en.month == '3' || bsDate.en.month == '4' || bsDate.en.month == '5' || bsDate.en.month == '6' || bsDate.en.month == '7' || bsDate.en.month == '8' || bsDate.en.month == '9' ? '0' + bsDate.en.month : bsDate.en.month) + '/' + bsDate.en.year);
  }

  addRow() {
    console.log("ADDROW");
    try {
      if (this.TrnMainObj.ProdList == null) {
        this.TrnMainObj.ProdList = [];
      }
      // Duplicate Item with same batch check
      // let duplicatecheck = 0;
      // if (this.TrnMainObj.ProdList == null) {
      //   this.TrnMainObj.ProdList = [];
      // }
      // this.TrnMainObj.ProdList.reduce((aac, obj) => {
      //   var existitem = aac.find(
      //     item => item.MCODE === obj.MCODE && item.BATCH === obj.BATCH
      //   );
      //   if (existitem && this.TrnMainObj.VoucherType != VoucherTypeEnum.SalesOrder) {
      //     alert("Duplicate Item with same batch detected...");
      //     duplicatecheck = 1;
      //     return;
      //   }
      //   aac.push(obj);
      //   return aac;
      // }, []);
      // if (duplicatecheck == 1) {
      //   return false;
      // }

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue
      ) {
        // if (
        //   this.TrnMainObj.ProdList.some(x => x.EXPDATE == null && x.MCODE != null) &&
        //   this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        // ) {
        //   this.alertService.warning(
        //     "Expiry Date of Item Not Detected..Please Review the list..."
        //   );
        //   return false;
        // }

        let expitem = this.TrnMainObj.ProdList.filter(x => new Date(x.EXPDATE) < new Date(new Date().setDate(new Date().getDate() - 1)) && x.MCODE != null)[0];
        if (expitem != null &&
          this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)) {
          this.alertService.warning(
            'Expired Item Detected : ' + expitem.ITEMDESC + '..Please Review the list...'
          );
          return false;
        }
        if (
          this.TrnMainObj.ProdList.some(
            x => new Date(x.MFGDATE) > new Date() && x.MCODE != null &&
              this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
          )
        ) {
          console.log("reachP", this.TrnMainObj.ProdList);
          this.alertService.warning(
            'Invalid Manufacture Date Item Detected..Please Review the list...'
          );
          return false;
        }

        if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Sales && this.activeurlpath == 'add-membership-sales-invoice'){
          if(this.TrnMainObj.ProdList.length > 0){
            this.alertService.warning("Only single product can be selected for Membership Sales Invoice");
            return false;
          }
        }
        // if (
        //   this.TrnMainObj.ProdList.some(
        //     x => x.MCODE != null && this.nullToZeroConverter(x.Quantity) < 1
        //   ) &&
        //   this.TrnMainObj.ProdList.some(x => x.PTYPE !== 6)
        // ) {
        //   this.alertService.warning(
        //     `Qty Is Not Specified For The Item ${this.TrnMainObj.ProdList[this.]}. Pls, Check Your Entry.`
        //   );
        //   return false;
        // }

        // let invalidQtyValidate = this.TrnMainObj.ProdList.filter( x => x.Quantity <=0)[0]
        // if(invalidQtyValidate !=null){
        //   this.alertService.warning(
        //     `Qty Is Not Specified For The Item ${invalidQtyValidate.ITEMDESC}(${invalidQtyValidate.MENUCODE}). Pls, Check Your Entry.`
        //   );
        //   return false;
        // }



      }
      // this.TrnMainObj.ProdList.forEach(x => x.inputMode = false);
      let newRow = <TrnProd>{};
      newRow.inputMode = true;
      newRow.MENUCODE = null;
      newRow.ITEMDESC = null;
      newRow.RATE = null;
      newRow.NCRATE = null;
      newRow.AMOUNT = null;
      newRow.INDDISCOUNTRATE = null;
      newRow.INDDISCOUNT = null;
      newRow.DISCOUNT = null;
      newRow.VAT = null;
      newRow.NETAMOUNT = null;
      newRow.ITEMTYPE = null;
      newRow.RECEIVEDTYPE = null;
      newRow.WAREHOUSE = null;
      newRow.BC = null;
      newRow.ALTERNATEQUANTIY = null;
      newRow.PRATE =0;
      newRow.isConfirmed = false;
      newRow.IsAuthorizeForNegative = false;

      this.TrnMainObj.ProdList.push(newRow);
      

      this.checkBatchWiseApiResponses = false;
      return true;
    } catch (ex) {
      // this.alertService.error(ex);
      return false;
    }

    
  }

  loadSODataToSales(VCHR) {
    this.masterService
      .masterPostmethod('/getSalesBillFromSO', { REFBILL: VCHR })
      .subscribe(
        data => {
          if (data.status === 'ok') {
            this.TrnMainObj = data.result;
            // this.TrnMainObj.Mode = "VIEW";
            // this.ReCalculateBill();

            this.trnmainBehavior.next(this.TrnMainObj);
          }
        },
        error => {
          this.trnmainBehavior.complete();
        },
        () => this.trnmainBehavior.complete()
      );
    // });
  }

  RealQuantitySet(i, CONFACTOR: number = 1) {
    if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DeliveryReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry
    ) {
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) * CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].RealQty = 0;
      this.TrnMainObj.ProdList[i].AltQty = 0;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Delivery ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation
    ) {
      // alert("DispatchOut")
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
    ) {
      if (this.TrnMainObj.ProdList[i].Quantity > 0) {
        // .CONFACTOR *
        this.TrnMainObj.ProdList[i].REALQTY_IN =
          this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
          CONFACTOR;
        this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
          i
        ].Quantity;
        this.TrnMainObj.ProdList[i].RealQty = 0;
        this.TrnMainObj.ProdList[i].AltQty = 0;
        this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      } else if (this.TrnMainObj.ProdList[i].Quantity < 0) {
        // .CONFACTOR *
        this.TrnMainObj.ProdList[i].RealQty =
          -1 * this.TrnMainObj.ProdList[i].Quantity * CONFACTOR;
        this.TrnMainObj.ProdList[i].AltQty =
          -1 * this.TrnMainObj.ProdList[i].Quantity;
        this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
        this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
        this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      }
    } else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
      /*
           none of the value are 0 here
           later while save the value it will checks StockSettlement and then return 0 there.
           */
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].Quantity;
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrderCancel
    ) {
      /*
           none of the value are 0 here
           later while save the value it will checks StockSettlement and then return 0 there.
           */
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].REALQTY_IN =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].Quantity;
      // .CONFACTOR *
      this.TrnMainObj.ProdList[i].RealQty =
        this.nullToZeroConverter(this.TrnMainObj.ProdList[i].Quantity) *
        CONFACTOR;
      this.TrnMainObj.ProdList[i].AltQty = this.TrnMainObj.ProdList[i].Quantity;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      this.TrnMainObj.ProdList[i].REALQTY_IN = 0;
      this.TrnMainObj.ProdList[i].ALTQTY_IN = 0;
    } else if (
      this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoiceCancel
    ) {
      this.TrnMainObj.ProdList[i].REALQTY_IN = this.nullToZeroConverter(
        this.TrnMainObj.ProdList[i].RealQty
      );
      this.TrnMainObj.ProdList[i].ALTQTY_IN = this.TrnMainObj.ProdList[
        i
      ].AltQty;
      this.TrnMainObj.ProdList[i].VoucherType = this.TrnMainObj.VoucherType;
      this.TrnMainObj.ProdList[i].RealQty = 0;
      this.TrnMainObj.ProdList[i].AltQty = 0;
    } else {
      alert('Vouchertype not found please contact admin.');
    }
  }

  // setunit(baseRate: number, baseRate2: number, activerowIndex, altunitObj) {
  //   if (altunitObj == null) {
  //     altunitObj = <any>{};
  //   }
  //   this.TrnMainObj.ProdList[activerowIndex].CONFACTOR = altunitObj.CONFACTOR;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = altunitObj.ALTUNIT;
  //   this.TrnMainObj.ProdList[activerowIndex].UNIT = altunitObj.BASEUOM;
  //   this.TrnMainObj.ProdList[activerowIndex].RATE = baseRate;
  //   this.TrnMainObj.ProdList[activerowIndex].REALRATE = baseRate;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
  //     baseRate * altunitObj.CONFACTOR;
  //   this.TrnMainObj.ProdList[activerowIndex].ALTRATE2 =
  //     baseRate2 * altunitObj.CONFACTOR;
  //     this.TrnMainObj.ProdList[activerowIndex].ALTMRP =  this.TrnMainObj.ProdList[activerowIndex].MRP* altunitObj.CONFACTOR;
  //   this.RealQuantitySet(activerowIndex, altunitObj.CONFACTOR);
  // }
  setAltunitDropDownForView(activerowIndex) {
 
    if (this.TrnMainObj.ProdList[activerowIndex].Ptype == 10) {
      //service item like delivery charges
    }
    else {
      if (this.TrnMainObj.ProdList[activerowIndex].ALTUNIT == null) { console.log("null", this.TrnMainObj.ProdList[activerowIndex].MCODE); }
      this.TrnMainObj.ProdList[
        activerowIndex
      ].ALTUNITObj = this.TrnMainObj.ProdList[
        activerowIndex
      ].Product.AlternateUnits.filter(
        x => (x.ALTUNIT == null ? '' : x.ALTUNIT).toLowerCase() == (this.TrnMainObj.ProdList[activerowIndex].ALTUNIT == null ? '' : this.TrnMainObj.ProdList[activerowIndex].ALTUNIT).toLowerCase()
      )[0];
    }
  }

  setAltunitDropDownForViewStock(activerowIndex) {
    this.masterService.masterGetmethod(
      '/getAltUnitsOfItem/' + this.TrnMainObj.ProdList[activerowIndex].MCODE
    )
      .subscribe(res => {
        if (res.status = 'ok') {
          this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = JSON.parse(res.result);
        }
      });

    this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj =
      this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ALTUNIT === this.TrnMainObj.ProdList[activerowIndex].ALTUNIT);
  }

  getItemGroupWiseRate(activerowIndex) {
    
    let groupWiseRate=[];
    this.masterService.masterGetmethod(
      `/getRateGroupWiseRATE?mcode=${this.TrnMainObj.ProdList[activerowIndex].MCODE}&division=${this.userProfile.userDivision}`
    )
      .subscribe(res => {
        if (res.status = 'ok') {
          groupWiseRate = JSON.parse(res.result);
          if(groupWiseRate.length){
            
            this.TrnMainObj.ProdList[activerowIndex].RATE =  (this.userSetting.ENABLE_WSRATE_INSORDER==1)? groupWiseRate[0].RATE_B: groupWiseRate[0].RATE;            
            if(this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER ==0 && this.TrnMainObj.ProdList[activerowIndex].ISVAT ==1){
              this.TrnMainObj.ProdList[activerowIndex].RATE =  (this.TrnMainObj.ProdList[activerowIndex].RATE * this.userSetting.VatConRate);
            }                        
            if(this.TrnMainObj.ProdList[activerowIndex].ALTUNIT != this.TrnMainObj.ProdList[activerowIndex].BASEUNIT){
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE * this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR;
            }else{
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE;
            }
          }
        }
      });
  }

  getItemRelatedDiscount(activerowIndex:number,discountParam:any):void {
    let itemWiseDiscount=[];
    let itemObj= {mode:null, data: discountParam};
    this.masterService.masterPostmethod(
      `/getItemRealtedDiscount`,itemObj
    )
      .subscribe(res => {
        if (res.status == 'ok') {
          itemWiseDiscount = res.result;
          
          if(itemWiseDiscount.length){
             
            if(this.userSetting.EnableContractPrice ==1){
              this.TrnMainObj.ProdList[activerowIndex].ALTRATE = itemWiseDiscount[0].RATEORDIS;  //Direct change on rate field if contract price is assign for party
              this.TrnMainObj.ProdList[activerowIndex].RATE = itemWiseDiscount[0].RATEORDIS;  
            }else{
              this.TrnMainObj.ProdList[activerowIndex].INDDISCOUNT = itemWiseDiscount[0].RATEORDIS; //Treat contract price as discount
            }
          
            this.ReCalculateBillWithNormal();
          }
        }
      });
  }



  checkProductMulitplePriceBatchWise(activerowIndex) {
    this.masterService.masterGetmethod(
      '/getProuductMultiplePriceBatchWise/' + this.TrnMainObj.ProdList[activerowIndex].MCODE
    )
      .subscribe(res => {

        if (res.status == 'ok') {
          if (res.message == 'SHOWPRICEPOPUP') {
            this.showBatchWiseQtyEntryPopup = 'SHOWPRICEPOPUP';
          } else {
            this.showBatchWiseQtyEntryPopup = '';
          }
        }
      });
  }

  getParentItemStock(activerowIndex){
    this.masterService
    .masterGetmethod(
      `/getParentItemStock?mcode=${this.TrnMainObj.ProdList[activerowIndex].PMCODE}&warehouse=${this.TrnMainObj.MWAREHOUSE}&phiscalid=${this.TrnMainObj.PhiscalID}&division=${this.TrnMainObj.DIVISION}` 
  
    ).subscribe((res:any) =>{
      if(res.status=='ok'){
        this.TrnMainObj.ProdList[activerowIndex].STOCK = res.result;
      }
    })


  }

  assignPrateFromMenuItem(value:any,activerowIndex:number){
    this.TrnMainObj.ProdList[activerowIndex].RATE = value.PRATE;
    this.TrnMainObj.ProdList[activerowIndex].SELLRATEBEFORETAX = value.RATE;
    this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.PRATE;
    this.TrnMainObj.ProdList[activerowIndex].RECRATE = value.RECRATE;
    this.TrnMainObj.ProdList[activerowIndex].POSTOCK = value.POSTOCK;
    this.TrnMainObj.ProdList[activerowIndex].PREV_PRATE = value.PREV_PRATE
  }
  getAllUnitsOfItem(activerowIndex) {
    let alternateUnitList = [];
    let MappedalternateUnitList = [];
    this.masterService
      .masterGetmethod(
        '/getAllUnitsOfItem/' +
        this.TrnMainObj.ProdList[activerowIndex].MCODE
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (
              this.TrnMainObj.ProdList[activerowIndex].Product == null
            ) {
              this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
            }
            this.TrnMainObj.ProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ProdList[activerowIndex].MCODE;
            alternateUnitList = JSON.parse(res.result);
            for (let it of alternateUnitList) {
              let alternateUnitObj = <AlternateUnit>{};
              alternateUnitObj.ALTUNIT = it.UNIT;
              alternateUnitObj.PRATE_A = it.PRATE_A;
              alternateUnitObj.MCODE = it.MCODE;
              alternateUnitObj.ISDEFAULTPUNIT = it.ISDEFAULTPUNIT;
              alternateUnitObj.ISDEFAULT = it.ISDEFAULT;
              alternateUnitObj.CONFACTOR = this.nullToZeroConverter(it.CONFACTOR) == 0 ? 1 : it.CONFACTOR;
              alternateUnitObj.RATE_A = it.RATE_A;
              alternateUnitObj.SRATE_DISCOUNT = it.SRATE_DISCOUNT;
              alternateUnitObj.WSRATE_DISCOUNT = it.WSRATE_DISCOUNT;
              MappedalternateUnitList.push(alternateUnitObj);
            }

            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
              let baseUnitList = MappedalternateUnitList.filter(x => x.ALTUNIT == this.TrnMainObj.ProdList[activerowIndex].BASEUNIT);
              if (baseUnitList != null) {
                this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = baseUnitList;
              } else {
                this.alertService.info("Product UNIT is not available");
              }
            } else {
              this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = MappedalternateUnitList;
            }

            this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits[0];
            this.TrnMainObj.ProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
            this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.CONFACTOR;

            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
              this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].BASEUNIT;
            } else {
                                    
                if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase){
                  let isdefaultPIUNIT = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ISDEFAULTPUNIT == 1)[0];    
                  if (isdefaultPIUNIT != null) { 
                    this.assignDefaultUnit(activerowIndex,isdefaultPIUNIT);
                  }else{
                    this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
                  }                  
                }
                else if(this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType == VoucherTypeEnum.Sales ){
                  let isdefaultSIUNIT = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ISDEFAULT == 1)[0];     
                  if (isdefaultSIUNIT != null) { 
                    this.assignDefaultUnitSI(activerowIndex, isdefaultSIUNIT);
                  }else{
                    this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
                  }          
                }               
               else {
                
                this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
              }
            }
          } else {
          }
        },
        error => {
        }
      );

  }

  assignDefaultUnit(activerowIndex:number,isdefaultPIUNIT:any){
    this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = isdefaultPIUNIT.ALTUNIT;
    this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = isdefaultPIUNIT.CONFACTOR;
    
    if (this.activeurlpath != 'add-sales-invoice-wholesale') {
      this.TrnMainObj.ProdList[activerowIndex].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE * this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR;
    }
  }

  assignDefaultUnitSI(activerowIndex:number, isdefaultSIUNIT:any){
    this.TrnMainObj.ProdList[activerowIndex].ALTUNIT = isdefaultSIUNIT.ALTUNIT;
    this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = isdefaultSIUNIT.CONFACTOR;
  }

  getAllSelingPriceOfItem(activerowIndex) {
    let altSellingPriceList = [];
    let mappedAltSellingPriceList = [];
    let mcode;
    let vchrno;
    if (this.masterService.is_PurchaseOrder == true) {
      mcode = this.TrnMainObj.PODetailList[activerowIndex].MCODE;
    } else {
      mcode = this.TrnMainObj.ProdList[activerowIndex].MCODE;
    }

    if(this.TrnMainObj.Mode.toUpperCase() == "EDIT"){
      vchrno = this.TrnMainObj.VCHRNO;
    }else{
      vchrno = 'default';
    }

      this.getAllSellingPirceOfItem(mcode,vchrno)
      .subscribe(
        res => {
          console.log("result", res);
          if (res.status === 'ok') {
            if (this.masterService.is_PurchaseOrder != true) {
              if (
                this.TrnMainObj.ProdList[activerowIndex].Product == null
              ) {
                this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
              }
            }

            //this.priceChangeList = this.TrnMainObj.ProdList[activerowIndex].Product.AltSellingPrices;
            altSellingPriceList = JSON.parse(res.result);
            console.log(res.result);
            console.log("@get all selling price of item", altSellingPriceList);
            let i = 1;
            for (let item of altSellingPriceList) {
              let altSellingPriceObj = <AltSellingPrice>{}
              altSellingPriceObj.SNO = i
              altSellingPriceObj.UNIT = item.UNIT;
              altSellingPriceObj.MCODE = item.MCODE;
              altSellingPriceObj.CRATE_A = item.RATE;
              altSellingPriceObj.WSRATE_A=item.W_MRP;
              altSellingPriceObj.NRATE_A = null;
              altSellingPriceObj.CONFACTOR = item.CONFACTOR;
              mappedAltSellingPriceList.push(altSellingPriceObj);
              i++;
            }
            this.priceChangeList = mappedAltSellingPriceList;
            if (this.masterService.is_PurchaseOrder != true) {
              this.TrnMainObj.ProdList[activerowIndex].Product.AltSellingPrices = mappedAltSellingPriceList;
              // this.showPriceChangeDetail = true; 
              this.selectedProdcutRecMargin = this.TrnMainObj.ProdList[activerowIndex].RECRATE;
            }
            console.log("LABELMRP", res, activerowIndex);
            this.TrnMainObj.ProdList[activerowIndex].LABELMRP = res.result2? res.result2:0;

          } else {
          }
        },
        error => {
        }
      );

  }




  getPricingOfItem(activerowIndex, batchcode = '', getAltunitListFromApi = true) {

    let getpricingObj = {
      mcode: this.TrnMainObj.ProdList[activerowIndex].MCODE,
      batchcode: batchcode,
    };



    this.masterService
      .masterPostmethod('/getBatchwiseItemPriceanddiscounts', getpricingObj)
      .subscribe(res => {
        if (res.status === 'ok') {
          this.AssignSellingPriceAndDiscounts(JSON.parse(res.result), activerowIndex);
          if (getAltunitListFromApi) {
            this.masterService
              .masterGetmethod(
                '/getAltUnitsOfItem/' +
                this.TrnMainObj.ProdList[activerowIndex].MCODE
              )
              .subscribe(
                res => {
                  if (res.status === 'ok') {
                    if (
                      this.TrnMainObj.ProdList[activerowIndex].Product == null
                    ) {
                      this.TrnMainObj.ProdList[activerowIndex].Product = <Product>{};
                    }
                    this.TrnMainObj.ProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ProdList[activerowIndex].MCODE;
                    this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits = JSON.parse(res.result);
                    this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ProdList[activerowIndex].Product.AlternateUnits[0];
                    this.TrnMainObj.ProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.ALTUNIT;
                    this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj.CONFACTOR;


                    // let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
                    // let rate2 = 0;
                    // if (this.TrnMainObj.VoucherType == VoucherTypeEnum.PurchaseOrder || this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase) {
                    //   rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
                    // } else {
                    //   rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
                    // }
                    // this.setunit(
                    //   rate1,
                    //   rate2,
                    //   activerowIndex,
                    //   this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj
                    // );
                  } else {
                  }
                },
                error => {
                }
              );
          } else {
            let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
            let rate2 = 0;
            if (
              this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
              this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase 
            ) {
              rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
            } else {
              rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
            }
            // this.setunit(
            //   rate1,
            //   rate2,
            //   activerowIndex,
            //   this.TrnMainObj.ProdList[activerowIndex].ALTUNITObj
            // );
            this.ReCalculateBillWithNormal();
          }
        } else {
          alert(res.result);
        }
      });
  }

  AssignSellingPriceAndDiscounts(PriceRate: PriceRate[], activerowIndex) {
    // Unused for AtoZ commented by Bzu
    console.log('Check PriceRate', PriceRate);
    let priceidO = PriceRate.filter(x => x.ColType === 'priceid')[0];

 
    if (priceidO != null) {
      this.TrnMainObj.ProdList[activerowIndex].PRICEID = priceidO.ColName;
    }
    let orgType = this.userProfile.CompanyInfo.ORG_TYPE;
    if (orgType.toLowerCase() === 'superdistributor') {
      let spp = PriceRate.filter(x => x.ColName === 'superdistributorlandingprice')[0];
      let ssp = <PriceRate>{};

      ssp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'superdistributor')[0];

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlementEntryApproval ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Stockadjustment
      ) {
        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          spp === undefined || spp == null ? 0 : this.nullToZeroConverter(spp.Value);
        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          ssp === undefined || ssp == null ? 0 : this.nullToZeroConverter(ssp.Value);
      }
      else {
     
        this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          ssp === undefined || ssp == null ? 0 : this.nullToZeroConverter(ssp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          spp === undefined || spp == null ? 0 : this.nullToZeroConverter(spp.Value);
      }
    } else if (orgType.toLowerCase() === 'distributor') {
      let dpp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'superdistributor')[0];
      let dsp = PriceRate.filter(x => x.ColName === 'distributor' && x.ColType === 'sellingprice')[0];

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
      ) {
        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          dpp === undefined || dpp == null ? 0 : this.nullToZeroConverter(dpp.Value);

        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          dsp === undefined || dsp == null ? 0 : this.nullToZeroConverter(dsp.Value);
      } else {
        this.TrnMainObj.ProdList[activerowIndex].REALRATE =
          this.TrnMainObj.ProdList[activerowIndex].ALTRATE =
          this.TrnMainObj.ProdList[activerowIndex].RATE =
          dsp === undefined || dsp == null ? 0 : this.nullToZeroConverter(dsp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          dpp === undefined || dpp == null ? 0 : this.nullToZeroConverter(dpp.Value);
      }
    } else {
      let rsp = <any>{};
      let rpp = PriceRate.filter(x => x.ColType === 'sellingprice' && x.ColName === 'distributor')[0];

      rsp = PriceRate.filter(
        x => x.ColName === 'retailer' && x.ColType === 'sellingprice'
      )[0];
      // }

      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance
      ) {
        this.TrnMainObj.ProdList[
          activerowIndex
        ].PRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].REALRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE =
          rpp === undefined || rpp == null
            ? 0
            : this.nullToZeroConverter(rpp.Value);

        this.TrnMainObj.ProdList[activerowIndex].SPRICE =
          rsp === undefined || rsp == null
            ? 0
            : this.nullToZeroConverter(rsp.Value);
      } else {
        this.TrnMainObj.ProdList[
          activerowIndex
        ].REALRATE = this.TrnMainObj.ProdList[
          activerowIndex
        ].ALTRATE = this.TrnMainObj.ProdList[activerowIndex].RATE =
          rsp === undefined || rsp.Value == null
            ? 0
            : this.nullToZeroConverter(rsp.Value);

        this.TrnMainObj.ProdList[activerowIndex].PRATE =
          rpp === undefined || rpp == null
            ? 0
            : this.nullToZeroConverter(rpp.Value);
      }
    }
  }
  MergeSameItemWithSameBatchOfProd() {
    // let MergedProd=[];
    // Rx.Observable.from(this.TrnMainObj.ProdList)
    // .groupBy(x =>{ x.MCODE,x.ITEMDESC,x.MENUCODE}) // using groupBy from Rxjs
    // .flatMap(group => group.toArray())// GroupBy dont create a array object so you have to flat it
    // .map(g => {// mapping
    //   return {
    //     MCODE: g[0].MCODE,//take the first name because we grouped them by name
    //     Quantity: _.sumBy(g, 'Quantity'),
    //     REALQTY_IN: _.sumBy(g, 'REALQTY_IN'),
    //   }
    // })
    // .toArray() //.toArray because I guess you want to loop on it with ngFor
    // .do(sum => console.log('sum:', sum)) // just for debug
    // .subscribe(d => MergedProd = d);
  }

  addRowForTransaction(index) {
    try {
      if (index === -1) {
        // this.TrnMainObj.TrntranList[0].ROWMODE =  "new";
        this.AddNewTrnTranRow(index);
        return;
      }

      if (this.TrnMainObj.TrntranList[index + 1]) return; // prevent to add row if selected at the item at somewhere except last

      let rm = this.TrnMainObj.TrntranList[index].ROWMODE;
      if (rm === 'new') {
        this.TrnMainObj.TrntranList[index].ROWMODE = 'new';
        this.AddNewTrnTranRow(index);
      } else if (rm === 'edit') {
        this.TrnMainObj.TrntranList[index].ROWMODE = 'edit';
      }
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  AddNewTrnTranRow(index) {
    try {
      if (!this.TrnMainObj.TrntranList) {
        this.TrnMainObj.TrntranList = [];
      }

      let currentObj = this.TrnMainObj.TrntranList[index];

      if (this.TrnMainObj.TrntranList[index + 1]) {
        return;
      }

      if (
        index !== -1 &&
        (!currentObj.AccountItem ||
          currentObj.AccountItem.ACID === undefined ||
          currentObj.AccountItem.ACID === '')
      ) {
        // this.alertService.info("Please Select A/C");
        alert('Please Select A/C');
        return;
      }

      if (
        index !== -1 &&
        ((currentObj.DRAMNT === 0 || currentObj.DRAMNT == null) &&
          (currentObj.CRAMNT === 0 || currentObj.CRAMNT == null))
      ) {
        alert('Debit Amount or Credit Amount is Required.');
        return;
      }

      let newRow = <Trntran>{};
      let newaclist: TAcList = <TAcList>{};
      newRow.AccountItem = newaclist;
      newRow.inputMode = true;
      newRow.editMode = true;

      newRow.ROWMODE = 'new';
      newRow.PartyDetails = [];
      this.TrnMainObj.TrntranList.push(newRow);
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  deleteAccountTrnRow(index) {
    try {
      if (this.TrnMainObj.TrntranList.length < 1) return;

      if (this.TrnMainObj.TrntranList.length === 1) {
        this.TrnMainObj.TrntranList.splice(index, 1);
        this.addRowForTransaction(-1);
        return;
      }
      let rm = this.TrnMainObj.TrntranList[index].ROWMODE;
      if (rm === 'new') {
        this.TrnMainObj.TrntranList.splice(index, 1);
      } else if (rm === 'save' || rm === 'edit') {
        this.TrnMainObj.TrntranList.splice(index, 1);
      }
    } catch (ex) {
      this.alertService.error(ex);
    }
  }

  calculateDrCrDifferences() {
    try {
      this.calculateCrDrTotal();
      this.differenceAmount = 0;
      if (
        this.TrnMainObj.VoucherType !== VoucherTypeEnum.Journal &&
        this.TrnMainObj.VoucherType !== VoucherTypeEnum.ContraVoucher
      ) {
        return;
      }
      let diffAmount: number = 0;
      this.TrnMainObj.TrntranList.forEach(tran => {
        diffAmount =
          diffAmount +
          ((tran.DRAMNT == null ? 0 : tran.DRAMNT) -
            (tran.CRAMNT == null ? 0 : tran.CRAMNT));
      });
      // this.differenceAmount = Math.abs(diffAmount);
      this.differenceAmount = diffAmount;
    } catch (ex) {
      this.alertService.error(ex);
    }
  }
  calculateCrDrTotal() {
    this.crTotal = 0;
    this.drTotal = 0;

    this.TrnMainObj.TrntranList.forEach(tran => {
      this.crTotal = this.crTotal + (tran.CRAMNT == null ? 0 : tran.CRAMNT);
      this.drTotal = this.drTotal + (tran.DRAMNT == null ? 0 : tran.DRAMNT);
    });
  }
  barcodeEnterCommand(event) {
    event.preventDefault();
    document.getElementById('itembarcode').blur();
    this.masterService
      .masterPostmethod('/getItemDetailsFromBarcode', {
        barcode: this.BarcodeFromScan
      })
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (res.message === 'multiItem') {
              this.ItemsListForMultiItemBarcode = JSON.parse(res.result);
              this.masterService.PlistTitle = 'itemList';
            } else {
              let item = JSON.parse(res.result);
              let index = this.TrnMainObj.ProdList.findIndex(
                x => x.MCODE === item.MCODE
              );

              if (index < 0) {
                this.addRow();
                this.assignValueToProdFromBarcode(
                  item,
                  this.TrnMainObj.ProdList.findIndex(x => x.MCODE == null)
                );
                document.getElementById('itembarcode').focus();
              } else {
                let alreadyExistingitem = this.TrnMainObj.ProdList[index];
                this.TrnMainObj.ProdList[index].Quantity += 1;
                this.RealQuantitySet(index, alreadyExistingitem.CONFACTOR);
                this.ReCalculateBillWithNormal();
                document.getElementById('itembarcode').focus();
              }
              this.BarcodeFromScan = '';
            }
          } else {
            this.alertService.error(res.result);
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }
  assignValueToProdFromBarcode(value, activerowIndex) {
    if (this.TrnMainObj.ProdList[activerowIndex] != null) {
      this.TrnMainObj.ProdList[activerowIndex].SELECTEDITEM = value;
      this.TrnMainObj.ProdList[activerowIndex].BC = value.BARCODE;
      this.TrnMainObj.ProdList[activerowIndex].PROMOTION = 0;
      this.TrnMainObj.ProdList[activerowIndex].BATCHSCHEME = null;
      this.TrnMainObj.ProdList[activerowIndex].ALLSCHEME = null;
      this.TrnMainObj.ProdList[activerowIndex].MRP = value.BatchObj.MRP;
      this.TrnMainObj.ProdList[activerowIndex].ISVAT = value.ISVAT;
      this.TrnMainObj.ProdList[activerowIndex].MENUCODE = value.MENUCODE;
      this.TrnMainObj.ProdList[activerowIndex].ITEMDESC = value.DESCA;
      this.TrnMainObj.ProdList[activerowIndex].MCODE = value.MCODE;
      this.TrnMainObj.ProdList[activerowIndex].GSTRATE_ONLYFORSHOWING =
        this.nullToZeroConverter(this.setting.appSetting.VATRate);
      this.TrnMainObj.ProdList[activerowIndex].GSTRATE = this.nullToZeroConverter(this.setting.appSetting.VATRate);
      this.TrnMainObj.ProdList[activerowIndex].WEIGHT = value.GWEIGHT;
      this.TrnMainObj.ProdList[activerowIndex].Mcat = value.MCAT;
      this.TrnMainObj.ProdList[activerowIndex].Product = value.Product;

      this.AssignSellingPriceAndDiscounts(
        value.PClist,
        activerowIndex

      );
      this.assignBatchToActiveProdRow(value.BatchObj, activerowIndex);
      this.TrnMainObj.ProdList[activerowIndex].Quantity = 1;
      this.TrnMainObj.ProdList[
        activerowIndex
      ].ALTUNITObj = this.TrnMainObj.ProdList[
        activerowIndex
      ].Product.AlternateUnits.filter(x => x.ISDEFAULT === 1)[0];

      let rate1 = this.TrnMainObj.ProdList[activerowIndex].RATE;
      let rate2 = 0;
      if (
        this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
        this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived
      ) {
        rate2 = this.TrnMainObj.ProdList[activerowIndex].SPRICE;
      } else {
        rate2 = this.TrnMainObj.ProdList[activerowIndex].PRATE;
      }

      this.ReCalculateBillWithNormal();
    }
  }

  assignBatchToActiveProdRow(value, activerowIndex) {
    console.log("batchvalue assign batch to active prod row",value);

    this.TrnMainObj.ProdList[activerowIndex].BATCH = value.BATCH;
    this.TrnMainObj.ProdList[activerowIndex].PRATE = value.costprice;
    this.TrnMainObj.ProdList[activerowIndex].SELLRATEBEFORETAX = value.SELLRATEBEFORETAX;
    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
      this.TrnMainObj.ProdList[activerowIndex].PBATCH = value.BATCH;
    }
    
    // this.TrnMainObj.ProdList[activerowIndex].BC = value.BCODE;
    this.TrnMainObj.ProdList[activerowIndex].MFGDATE =
      value.MFGDATE == null ? '' : value.MFGDATE.toString().substring(0,10);
    this.TrnMainObj.ProdList[activerowIndex].EXPDATE =
      value.EXPDATE == null ? '' : value.EXPDATE.toString().substring(0,10);
    this.TrnMainObj.ProdList[activerowIndex].STOCK = value.STOCK;
    this.TrnMainObj.ProdList[activerowIndex].WAREHOUSE = value.WAREHOUSE;
    this.TrnMainObj.ProdList[activerowIndex].BATCHSCHEME = value.SCHEME;
    this.TrnMainObj.ProdList[activerowIndex].PRATE = value.costprice;


    if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
      this.TrnMainObj.ProdList[activerowIndex].REPACKRATE = value.RATE;
      this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.RATE * 
      this.nullToZeroConverter(this.TrnMainObj.ProdList[activerowIndex].CONFACTOR);  
      let rate =  this.TrnMainObj.ProdList[activerowIndex].REPACKRATE * this.TrnMainObj.ProdList[activerowIndex].CONFACTOR;
      this.TrnMainObj.ProdList[activerowIndex].RATE = rate;
    }else{
      this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value.RATE;
    }

    if (this.TrnMainObj.ProdList[activerowIndex].UNIT == this.TrnMainObj.ProdList[activerowIndex].BASEUNIT) {
      this.TrnMainObj.ProdList[activerowIndex].RATE = value.RATE;
      console.log("ra",value.RATE,this.TrnMainObj.ProdList[activerowIndex].RATE);
    } else {
      this.TrnMainObj.ProdList[activerowIndex].RATE = value.RATE * this.nullToZeroConverter(this.TrnMainObj.ProdList[activerowIndex].ALTCONFACTOR);
    }

    console.log("prodlist",this.TrnMainObj.ProdList);
  }

  diffAmountItemForAccount: number = 0;
  diffAmountDrCrType: string = '';
  getAccountWiseTrnAmount(acid: string) {
    if (!acid || acid == null || acid === undefined) return;

    this.diffAmountItemForAccount = 0;
    this.diffAmountDrCrType = '';
    let companyId = this.authservice.getCurrentCompanyId();
    let requestType = 0;
    if (this.TrnMainObj.VoucherType === 22 || this.TrnMainObj.VoucherType === 23) {
      requestType = 1;
    }

    this.masterService
      .getAccountWiseTrnAmount(
        this.TrnMainObj.TRNDATE.toString(),
        companyId,
        acid,
        this.TrnMainObj.DIVISION
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            this.diffAmountDrCrType = res.result < 0 ? 'Cr' : 'Dr';
            this.diffAmountItemForAccount = Math.abs(res.result);
          } else {
            this.diffAmountItemForAccount = 0;
            this.diffAmountDrCrType = '';
          }
        },
        error => {
          this.diffAmountItemForAccount = 0;
          this.diffAmountDrCrType = '';
        }
      );
  }

  CalculateApprovalBill(){
    try{
      console.log("CheckProdforApproval",this.TrnMainObj.ProdList)
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      this.TrnMainObj.TOTALSQTY = 0;

      this.TrnMainObj.ProdList.forEach( prod =>{
        let rQty;
        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.APPROVEDALTQTY);
            if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack){
              let rate = prod.REPACKRATE * prod.CONFACTOR;
              prod.RATE = rate;
            }  
        } else {
          rQty = this.nullToZeroConverter(prod.APPROVEDALTQTY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }

        // prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
        prod.APPROVEDQUANTITY= rQty;
        prod.Quantity =
        prod.RealQty = rQty;
        prod.REALQTY_IN = 0;
        prod.ALTQTY_IN = 0;
        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);
        prod.NETAMOUNT = prod.AMOUNT;

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        this.TrnMainObj.NETAMNT += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TOTALQTY += rQty;


      })

    }catch(error){
      this.alertService.error(`Error on calculating approval bill ${error} please contact to admin `);
    }   
  }

  ReCalculateBillWithNormal() {
    try {
      console.log("CheckProd#",this.TrnMainObj.ProdList)
      // return;
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.NETAMNT = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      this.TrnMainObj.TOTALSQTY = 0;
      this.TrnMainObj.TOTALECS = 0;
      // let rQty = 0;

      this.TrnMainObj.ProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
            if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack){
              let rate = prod.REPACKRATE * prod.CONFACTOR;
              prod.RATE = rate;
            }  
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          // prod.ALTRATE = this.nullToZeroConverter(prod.RATE)*this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
 
        if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack){
          prod.ROUTQTY = prod.ALTERNATEQUANTIY * this.nullToZeroConverter(prod.CONFACTOR);
         // prod.ALTRATE = prod.RATE * this.nullToZeroConverter(prod.CONFACTOR);
          
        }

        

        if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DeliveryReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry 
        ) {
          // .CONFACTOR *
          prod.REALRATE = prod.RATE;
          prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.REALQTY_IN = rQty;
          prod.RealQty = 0;
          prod.AltQty = 0;
        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Sales ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Delivery ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PerformaSalesInvoice ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation ||
          this.TrnMainObj.VoucherType ===VoucherTypeEnum.BatchAdjustment||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Stockadjustment ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionApproval
          
        ) {
          // alert("DispatchOut")
          // .CONFACTOR *
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        }
        else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance) {
          prod.REALRATE = prod.RATE;
          if (rQty > 0) {
            // .CONFACTOR *
            prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
            prod.Quantity =
              prod.REALQTY_IN = rQty;
            prod.RealQty = 0;
            prod.AltQty = 0;
          } else if (rQty < 0) {
            prod.ALTQTY_IN = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
            prod.Quantity =

              prod.RealQty = rQty;
            prod.REALQTY_IN = 0;
            prod.ALTQTY_IN = 0;
          }
        } else if (this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement) {
          /*
               none of the value are 0 here
               later while save the value it will checks StockSettlement and then return 0 there.
               */
          prod.ALTQTY_IN =
            prod.REALQTY_IN =
            prod.Quantity =
            prod.AltQty =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrderCancel
        ) {


        } else if (
          this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoiceCancel
        ) {

        } else {
          alert('Vouchertype not found please contact admin.');
        }

        // prod.AMOUNT =0;
        console.log("check ECS CHARGE", prod.HASECSCHARGE, prod.ECSRATE);
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 1) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (prod.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 2) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (prod.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 3) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (prod.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }


        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);
        prod.GROSSAMNT = rQty * this.nullToZeroConverter(prod.RATE);

        if (prod.Weighable === 'kg' || prod.Weighable === 'ltr') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT);
        }
        else if (prod.Weighable === 'gram' || prod.Weighable === 'ml') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT / 1000);
        }

        // primary discount used as Customer FIxed Discount
        // secondary discount as scheme
        // liquidation discount as contract discount

        // if(this.AdditionalObj.AdditionalObj.BEFOREFLATDISCRATE != null && this.AdditionalObj.AdditionalObj.BEFOREFLATDISCRATE >0){
        //   if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase){
        //     if(this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER'){

        //     }else{

        //     }
        //   }
        // }

        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER' ) {
              prod.RATE = prod.ALTRATE;
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
              prod.DISCOUNT = prod.INDDISCOUNT = prod.AMOUNT * (prod.INDDISCOUNTRATE / 100);
              
            } else {
              prod.INDDISCOUNT = ((prod.RATE * rQty) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
              
              /*calculation of flatdiscount*/
              prod.FLATDISCOUNT =  ((rQty * this.nullToZeroConverter(prod.RATE) +this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE) / 100)) 
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) 
              - this.nullToZeroConverter(prod.FLATDISCOUNT)
              - ((rQty * this.nullToZeroConverter(prod.RATE) +this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(prod.INDDISCOUNTRATE) / 100));
           
              if(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE > 0){
                prod.FLATDISCOUNT = prod.AMOUNT * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100); /*calculation of flatdiscount*/
                prod.AMOUNT = prod.AMOUNT - (prod.AMOUNT * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100)); 
                

              }
            }
          } else {
            prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
          }
        
        } else {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER') {
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
            } else {
              /*calculation of flat discount*/
              prod.FLATDISCOUNT = (rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE) / 100 );
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) 
              -((rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE) / 100 ))
              - this.nullToZeroConverter(prod.INDDISCOUNT);

              if(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE > 0){
              /*calculation of flat discount*/
                prod.FLATDISCOUNT = prod.AMOUNT * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100); 
                prod.AMOUNT = prod.AMOUNT - (prod.AMOUNT * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100));
              }

            }
          }

        }

        if(this.TrnMainObj.VoucherType == VoucherTypeEnum.DebitNote){
          this.calculateFlatDiscForDebitNote(prod,rQty);
        }

        
        prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
          0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);
        // prod.LiquiditionDiscount = this.nullToZeroConverter(prod.BaseLiquiditionDiscount) * prod.Quantity;
        if (this.TrnMainObj.Mode !== 'VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) === 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }

        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else {
          // prod.FLATDISCOUNT =
          //   ((prod.AMOUNT - disExcludingFlatDiscount) *
          //     this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT)) /
          //   totalAmt;
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived){ 
            this.calculateNetRateInFamount();
          }

         
        }
        //   prod.FixedDiscount = prod.RATE*prod.RealQty*this.CustomerObjForDiscount.CUS_FIX_DISCOUNT_PER/100
        //   prod.RemoteDiscount = prod.RATE*prod.RealQty*this.CustomerObjForDiscount.CUS_Remote_Discount/100
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);

        // prod.RATE = (this.nullToZeroConverter(prod.RATE)* this.nullToZeroConverter(prod.ALTCONFACTOR))
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }

        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;
        // let vatamnt = (prod.AMOUNT - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        let vatamnt = 0;
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE' && this.TrnMainObj.VATBILL != 1) {
          vatamnt = (prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        }
        else 
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.OpeningStockBalance ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockSettlement ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferIn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.BranchTransferOut ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.ProductionEntry ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssue ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.Repack ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.StockIssueReturn ||
          this.TrnMainObj.VoucherType === VoucherTypeEnum.RequisitionEntry ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.VATBILL == 1)) ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER' || this.TrnMainObj.VATBILL == 1)) ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesOrder && this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0) || //added by anubhav
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Quotation && this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0)||     //added by anubhav
          this.TrnMainObj.VoucherType ===VoucherTypeEnum.BatchAdjustment ||
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.DebitNote &&  this.TrnMainObj.VATBILL == 1)

        ) {
          vatamnt = 0;
        }
        else {
          vatamnt = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);          
        }
       
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' ) ||
              (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          if (prod.ISVAT == 1) {
            prod.TAXABLE = prod.AMOUNT;
            prod.NONTAXABLE = 0;
          } else if (prod.ISVAT == 0) {
            prod.TAXABLE = 0;
            prod.NONTAXABLE = prod.AMOUNT;
          }
        } else {
          if (prod.ISVAT == 1 && vatamnt > 0) {
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  )&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.TAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.TAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX);
            }

            prod.NONTAXABLE = 0;
            prod.VAT = vatamnt;
          } else {
            prod.TAXABLE = 0;
            prod.VAT = 0;
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType===VoucherTypeEnum.GoodsReceived)&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.NONTAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.NONTAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            }

            
          }
        }

        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE') ||
        (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          prod.NETAMOUNT = prod.AMOUNT - this.nullToZeroConverter(prod.INDDISCOUNT);
        } else {
          prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);;
        }

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        // this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.NETAMNT += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALQTY += rQty;
        this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);
        this.TrnMainObj.TOTALECS += this.nullToZeroConverter(prod.ECSAMOUNT);

        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

      });

      this.TrnMainObj.NETWITHOUTROUNDOFF = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETAMNT)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETWITHOUTROUNDOFF - this.TrnMainObj.NETAMNT;

    } catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }
  calculateFlatDiscForDebitNote(prod:TrnProd,rQty) {
    console.log("flat disocnut on DEBITTTT", prod.FLATDISCOUNT,this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE, this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE);
    // if(this.nullToZeroConverter(prod.FLATDISCOUNT) == 0){
      if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {
        prod.FLATDISCOUNT =  ((rQty * this.nullToZeroConverter(prod.RATE) +this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE) / 100)) ;
        let amount =  (rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) 
        - this.nullToZeroConverter(prod.FLATDISCOUNT)
        - ((rQty * this.nullToZeroConverter(prod.RATE) +this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(prod.INDDISCOUNTRATE) / 100));
  
        if(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE > 0){
          prod.FLATDISCOUNT = amount * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100); /*calculation of flatdiscount*/
        }
      }else{
        prod.FLATDISCOUNT = ((rQty * this.nullToZeroConverter(prod.RATE) +this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.BEFOREFLATDISCRATE) / 100)) ;
        let amount = (rQty * this.nullToZeroConverter(prod.RATE) + this.nullToZeroConverter(prod.ECSAMOUNT)) 
        - this.nullToZeroConverter(prod.FLATDISCOUNT)
        - this.nullToZeroConverter(prod.INDDISCOUNT);
  
        if(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE != null && this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE > 0){
          prod.FLATDISCOUNT = amount * (this.nullToZeroConverter(this.TrnMainObj.AdditionalObj.AFTERFLATDISCRATE)/100); /*calculation of flatdiscount*/
        }
      }
    // }
  }

  allowMinusStockInEditMode(activeRowIndex:number=0,fromSaveClick:boolean=false):boolean{
    let vType = this.TrnMainObj.VoucherType;

    if(this.TrnMainObj.ProdList[activeRowIndex].PTYPE == 10 || this.TrnMainObj.ProdList[activeRowIndex].NATURETYPE ==7){
      return true;
    }

    if(vType in AllowNegativeEditEnum){
        if(this.userSetting.BatchAutoNPI ===1){
          return true;
        }
    }

    


     /*Incase of single batch, Software do not  check the item  stock. */

     if(this.userSetting.ENABLESINGLEBATCHMODE == 1 ){

      if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase   ){
    

        if(this.userSetting.ALLOWMINUSTOCK_PURCHASE ==0){
          return true;
        }
        if(this.userSetting.ALLOWMINUSTOCK_PURCHASE ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }
        if(this.userSetting.ALLOWMINUSTOCK_PURCHASE ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_PURCHASE);
            return true;
          }
        }
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.OpeningStockBalance)  ){
        if(this.userSetting.ALLOWMINUSTOCK_OPENING ==0){
          return true;
        }
       
        if(this.userSetting.ALLOWMINUSTOCK_OPENING ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_OPENING ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_OPENING);
            return true;
          }
        }
       
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.BranchTransferOut)  ){
        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==0){
          return true;
        }
       
        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==2){
          if(!fromSaveClick){
            
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT,false,false,0);
            return true;
          }
        }
       
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.DebitNote)  ){
        if( this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==0){
          return true;
        }
        if( this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_DEBITNOTE);
            return true;
          }
        }
        
      }

       return false;
     }
     return false;
  }

  allowNegativeBilling(activeRowIndex:number=0,fromSaveClick:boolean=false,fromConsumption:boolean=false,formBarcode:boolean=false,bTotalQty=0):boolean{
    console.log("rakone", this.TrnMainObj.VoucherType , "active row", activeRowIndex, "PTYPE",this.TrnMainObj.ProdList[activeRowIndex].PTYPE);
    //Incase of single batch, Software do not  check the item  stock. 
    
    if(this.userSetting.ENABLESINGLEBATCHMODE == 1 ){
     
      if(fromSaveClick){
        return true;
      }

      /*system does not check stock for service item.For service items system take SERVICE10 as batch code */
     if(!fromConsumption){
      if(this.TrnMainObj.ProdList[activeRowIndex]?.PTYPE==10){
        return false;
      }

      if(this.TrnMainObj.ProdList[activeRowIndex]?.PTYPE==9){
        return false;
      }



     }
 
     if((this.TrnMainObj.VoucherType == VoucherTypeEnum.DebitNote)  ){
      if( this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==0){
        return true;
      }
      if( this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==1){
        if(!fromSaveClick){
          this.showAlertIfQtyExceedStock(activeRowIndex);
          return true;
        }
      }

      if(this.userSetting.ALLOWMINUSTOCK_DEBITNOTE ==2){
        if(!fromSaveClick){
          this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_DEBITNOTE,false,formBarcode,bTotalQty);
          return true;
        }
      }
      
    }

   

   

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.Sales || this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice)  ){
        if(this.userSetting.ALLOWMINUSTOCK_SALES ==0){
          return true;
        }
        if(this.userSetting.ALLOWMINUSTOCK_SALES ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_SALES ==2){

          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_SALES,false,formBarcode,bTotalQty);
            return true;
          }
        }
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.BranchTransferOut)  ){
        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==0){
          return true;
        }
       
        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT ==2){
          if(!fromSaveClick){
            
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_TRANSFEROUT,false,formBarcode,bTotalQty);
            return true;
          }
        }
       
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.StockIssue || this.TrnMainObj.VoucherType == VoucherTypeEnum.StockIssueReturn )  ){
        if( this.userSetting.ALLOWMINUSTOCK_STOCKISSUE ==0){
          return true;
        }
        if( this.userSetting.ALLOWMINUSTOCK_STOCKISSUE ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_STOCKISSUE ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_STOCKISSUE,false,formBarcode,bTotalQty);
            return true;
          }
        }
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack)  ){
        if(this.userSetting.ALLOWMINUSTOCK_REPACK ==0){
          return true;
        }
        if( this.userSetting.ALLOWMINUSTOCK_REPACK ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex);
            return true;
          }
        }

        if(this.userSetting.ALLOWMINUSTOCK_REPACK ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStock(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_REPACK);
            return true;
          }
        }
        
      }

      if((this.TrnMainObj.VoucherType == VoucherTypeEnum.ProductionEntry) ){

        if(!fromConsumption){
          return false;
        }

        if(this.userSetting.ALLOWMINUSTOCK_CONSUMPTION ==0){
          return true;          
        }
        if(this.userSetting.ALLOWMINUSTOCK_CONSUMPTION ==1){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStockForConsumption(activeRowIndex,0,fromConsumption);
            return true;
          }          
        }

        if(this.userSetting.ALLOWMINUSTOCK_CONSUMPTION ==2){
          if(!fromSaveClick){
            this.showAlertIfQtyExceedStockForConsumption(activeRowIndex,this.userSetting.ALLOWMINUSTOCK_CONSUMPTION,fromConsumption);
            return true;
          }
        }
        
      }

       return false;
     }
     return false;
  }

  showAlertIfQtyExceedStockForConsumption(activeRowIndex:number,settingLevel=0,fromConsumption:boolean=false){
    let totalQty=this.getTotalEnteredQtyForConsumption(activeRowIndex);
    let stock = this.TrnMainObj.ConsumptionProdList[activeRowIndex]?.STOCK;
    let itemName = this.TrnMainObj.ConsumptionProdList[activeRowIndex]?.ITEMDESC;

    if(this.TrnMainObj.Mode?.toUpperCase() == 'NEW' || this.TrnMainObj.Mode?.toUpperCase() == 'EDIT'  ){
      if(totalQty>stock){
        if(settingLevel == 0 || settingLevel == 1){
          this.alertService.info(`Item ${itemName} Entered Qty:${totalQty} Exceed the available Qty:${stock}`);
        }
        
        if(settingLevel ==2){
            
            if(this.checkForUserMinusStockAuthorization()){
              this.alertService.info(`Item ${itemName} Entered Qty:${totalQty} Exceed the available Qty:${stock}`);
            }
            if(!this.checkForUserMinusStockAuthorization()){
         
              this.showNegativeBillingInConsumption = true;

            }
  
        }
      }

      
    }


   
  }


  showAlertIfQtyExceedStock(activeRowIndex:number,settingLevel=0,fromConsumption:boolean=false,fromBarcode:boolean=false,bQty=0){
    let totalQty=this.getTotalEnteredQty(activeRowIndex);

    

    let stock = this.TrnMainObj.ProdList[activeRowIndex]?.STOCK;
    let itemName = this.TrnMainObj.ProdList[activeRowIndex]?.ITEMDESC;

    let currentProdItem = this.TrnMainObj.ProdList[activeRowIndex];
    if(fromConsumption){
      stock = this.TrnMainObj.ConsumptionProdList[activeRowIndex].STOCK;
      totalQty = this.getTotalEnteredQtyForConsumption(activeRowIndex);
    }
    let messageDynamic = this.selectedComponentName();
    
    if(fromBarcode){
      totalQty = bQty;
    }
    

    if(this.TrnMainObj.Mode?.toUpperCase() == 'EDIT' && (this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType == VoucherTypeEnum.OpeningStockBalance) ){
      stock = this.TrnMainObj.ProdList[activeRowIndex].TOTALOUTWARDSTOCK;
      
      if(stock> totalQty){
        this.alertService.info(`Item ${itemName} Entered Qty:${totalQty} Exceed the transaction Qty:${stock}`);
        if(settingLevel ==2){
            
  
            if(this.checkForUserMinusStockAuthorization()){
              this.alertService.info(`Item ${itemName} Entered Qty:${totalQty} Exceed the transaction Qty:${stock}`);
            }
  
            if(!this.checkForUserMinusStockAuthorization()){
              this.showNegativeBilling = true;
            }  
        }
      }
    }

    if(this.TrnMainObj.Mode?.toUpperCase() == 'NEW' || ((this.TrnMainObj.VoucherType==VoucherTypeEnum.DebitNote || this.TrnMainObj.VoucherType ==VoucherTypeEnum.BranchTransferOut)) ){
      console.log("reach down", this.TrnMainObj.VoucherType,totalQty,stock);
      console.log("actgive roew", this.TrnMainObj.ProdList[activeRowIndex], "units,", this.TrnMainObj.ProdList[activeRowIndex].BASEUNIT, this.TrnMainObj.ProdList[activeRowIndex].ALTERNATEUNIT);
    
      if(totalQty>stock){
        console.log("qtystock",totalQty,stock);
        if(settingLevel == 0 || settingLevel == 1){
          if(currentProdItem.BASEUNIT == currentProdItem.ALTUNIT){
            this.alertService.info(`Item ${itemName} , Entered Qty:${totalQty} ${currentProdItem.BASEUNIT} Exceed the available Qty :${stock} ${currentProdItem.BASEUNIT}`);
          }else{
            this.alertService.info(`Item ${itemName}, Enterd Qty: ${totalQty}  ${currentProdItem.BASEUNIT}  (${currentProdItem.ALTERNATEQUANTIY} ${currentProdItem.ALTUNIT} * ${currentProdItem.ALTCONFACTOR}) Exceed the available Qty : ${stock} ${currentProdItem.BASEUNIT}`);
          }
        }
        
        if(settingLevel ==2){
            
  
            if(this.checkForUserMinusStockAuthorization()){
              if(currentProdItem.BASEUNIT == currentProdItem.ALTUNIT){
                this.alertService.info(`Item ${itemName} Entered Qty:${totalQty} Exceed the available Qty:${stock}`);
              }else{
                this.alertService.info(`Item ${itemName}, Enterd Qty: ${totalQty} (${currentProdItem.ALTERNATEQUANTIY} * ${currentProdItem.ALTCONFACTOR}) Exceed the available Qty: ${stock}`);
              }
            }
  
            if(!this.checkForUserMinusStockAuthorization()){
              console.log("POP UP SHOWN")
              this.showNegativeBilling = true;
              // this.alertService.info(`User has no rights to perform ${messageDynamic} in negative stock.`);
              // this.TrnMainObj.ProdList[activeRowIndex].ALTERNATEQUANTIY =0;
              
              if(fromConsumption){
                this.TrnMainObj.ConsumptionProdList[activeRowIndex].ALTERNATEQUANTIY =0;
              }

            }
  
        }
      }

      
    }


   
  }

  selectedComponentName():string{
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.StockIssue){
      return 'stock issue';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Sales || this.TrnMainObj.VoucherType == VoucherTypeEnum.TaxInvoice){
      return 'sales';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.BranchTransferOut){
      return 'transfer out';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack){
      return 'repack';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.ProductionEntry){
      return 'production entry';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Purchase){
      return 'purchase';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.OpeningStockBalance){
      return 'opening stock entry';
    }
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.DebitNote){
      return 'purchase return';
    }
  }

  checkForUserMinusStockAuthorization():boolean{
     if(this.userProfile.userRights.filter(x=> x.right == 'AllowMinusStock')[0]?.value == 1){
       
       return true;
     }
     return false;
  }

  getTotalEnteredQty(acitveRowIndex:number):number{
    let totalQty = 0;
    if(this.TrnMainObj.ProdList[acitveRowIndex].UNIT == this.TrnMainObj.ProdList[acitveRowIndex].ALTUNIT){
      totalQty = this.TrnMainObj.ProdList[acitveRowIndex].ALTERNATEQUANTIY;
    }
    if(this.TrnMainObj.ProdList[acitveRowIndex].UNIT != this.TrnMainObj.ProdList[acitveRowIndex].ALTUNIT){
      totalQty = this.TrnMainObj.ProdList[acitveRowIndex].ALTERNATEQUANTIY * this.TrnMainObj.ProdList[acitveRowIndex].ALTCONFACTOR;
    }
    return totalQty;
  }

  getTotalEnteredQtyForConsumption(acitveRowIndex:number):number{
    let totalQty = 0;
    if(this.TrnMainObj.ConsumptionProdList[acitveRowIndex].UNIT == this.TrnMainObj.ConsumptionProdList[acitveRowIndex].ALTUNIT){
      totalQty = this.TrnMainObj.ConsumptionProdList[acitveRowIndex].ALTERNATEQUANTIY;
    }
    if(this.TrnMainObj.ConsumptionProdList[acitveRowIndex].UNIT != this.TrnMainObj.ConsumptionProdList[acitveRowIndex].ALTUNIT){
      totalQty = this.TrnMainObj.ConsumptionProdList[acitveRowIndex].ALTERNATEQUANTIY * this.TrnMainObj.ConsumptionProdList[acitveRowIndex].ALTCONFACTOR;
    }
    return totalQty;
  }


  NeedToTakePriceFromMenuItem():boolean{
    // If Single Batch setting is enabled price need to take from the Product master.
      if(this.userSetting.ENABLESINGLEBATCHMODE == 1){
        return true;
      }
      return false;
  }





  ReverseReCalculateBillWithNormal() {
    try {
    
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.GROSSAMNT = 0;
      this.TrnMainObj.TOTALSQTY = 0;
      // let rQty = 0;

      this.TrnMainObj.ProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        
        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
            prod.ALTRATE = (this.nullToZeroConverter(prod.NETAMOUNT) / this.nullToZeroConverter(this.userSetting.VatConRate))/rQty;
            prod.RATE = prod.ALTRATE;
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.ALTRATE = (this.nullToZeroConverter(prod.NETAMOUNT) / this.nullToZeroConverter(this.userSetting.VatConRate))/rQty;          
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
        console.log("prod",prod);
        if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        }
      else {
          alert('Vouchertype not found please contact admin.');
        }


        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 1) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 2) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        if (prod.HASECSCHARGE === 1 && this.userSetting.ENABLE_ECS == 3) {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.TaxInvoice || this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived) {
            prod.ECSAMOUNT = ((prod.RATE * rQty) * (this.userSetting.ECSRATE / 100));
          } else {
            prod.ECSAMOUNT = 0;
          }
        }
        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);

        if (prod.Weighable === 'kg' || prod.Weighable === 'ltr') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT);
        }
        else if (prod.Weighable === 'gram' || prod.Weighable === 'ml') {
          prod.WEIGHT = rQty * this.nullToZeroConverter(prod.PWEIGHT / 1000);
        }

        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {

          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER' ) {
              prod.RATE = prod.ALTRATE;
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
              prod.DISCOUNT = prod.INDDISCOUNT = prod.AMOUNT * (prod.INDDISCOUNTRATE / 100);
            } else {
              prod.INDDISCOUNT = ((prod.RATE * rQty) + this.nullToZeroConverter(prod.ECSAMOUNT)) * (prod.INDDISCOUNTRATE / 100);
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - ((rQty * this.nullToZeroConverter(prod.RATE) +
                  this.nullToZeroConverter(prod.ECSAMOUNT)) * (this.nullToZeroConverter(prod.INDDISCOUNTRATE) / 100))
            }
          } else {
            prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
          }
        
        } else {
          if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived || (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === "IMPORT_PURCHASE_ORDER")) {
            if (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE_ORDER') {
              prod.AMOUNT = prod.ALTERNATEQUANTIY * prod.ALTRATE;
            } else {
              prod.AMOUNT = (rQty * this.nullToZeroConverter(prod.RATE) +
                this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.INDDISCOUNT);
            }
          }

        }
        prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
          0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);        
        if (this.TrnMainObj.Mode !== 'VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) === 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }

        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else {
 
          if(this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived){ 
            this.calculateNetRateInFamount();
          }         
        }
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }

        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;
        // let vatamnt = (prod.AMOUNT - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        let vatamnt = 0;
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived ) && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE' && this.TrnMainObj.VATBILL != 1) {
          vatamnt = (prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);
        }
        else 
        if (
          (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && (this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' || this.TrnMainObj.VATBILL === 1))
        ) {
          vatamnt = 0;
        }
        else {
          vatamnt = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX)) * this.nullToZeroConverter(this.setting.appSetting.VATRate);          
        }
       
        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE' ) ||
              (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          if (prod.ISVAT == 1) {
            prod.TAXABLE = prod.AMOUNT;
            prod.NONTAXABLE = 0;
          } else if (prod.ISVAT == 0) {
            prod.TAXABLE = 0;
            prod.NONTAXABLE = prod.AMOUNT;
          }
        } else {
          if (prod.ISVAT == 1 && vatamnt > 0) {
            if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase || this.TrnMainObj.VoucherType === VoucherTypeEnum.GoodsReceived  )&& this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.TAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.TAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - this.nullToZeroConverter(prod.DISCOUNT) + this.nullToZeroConverter(prod.SERVICETAX);
            }


            prod.NONTAXABLE = 0;
            prod.VAT = vatamnt;
          } else {
            prod.TAXABLE = 0;
            prod.VAT = 0;
            if (this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag != 'IMPORT_PURCHASE') {
              prod.NONTAXABLE = prod.AMOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            } else {
              prod.NONTAXABLE = (prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) - prod.DISCOUNT + this.nullToZeroConverter(prod.SERVICETAX);
            }
          }
        }

        if ((this.TrnMainObj.VoucherType === VoucherTypeEnum.Purchase && this.TrnMainObj.AdditionalObj.tag == 'IMPORT_PURCHASE') ||
        (this.TrnMainObj.VoucherType === VoucherTypeEnum.PurchaseOrder && this.TrnMainObj.AdditionalObj.tag === 'IMPORT_PURCHASE_ORDER')) {
          prod.NETAMOUNT = prod.AMOUNT - this.nullToZeroConverter(prod.INDDISCOUNT);
        } else {
          prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);;
        }

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.GROSSAMNT += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALQTY += rQty;
        this.TrnMainObj.TOTALSQTY += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);

        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

      });

      this.TrnMainObj.NETAMNT = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETWITHOUTROUNDOFF)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETAMNT - this.TrnMainObj.NETWITHOUTROUNDOFF;

    } catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }

  getCustomerListRouteAndSalesmanWise(salesManId: any, route: any) {
    let data = { salesManId: salesManId, route: route };
    this.masterService.getCustomerListBasedUponRouteAndSalesman(data)
      .subscribe(
        (res) => {
          this.CustomerList = res;
          if (this.CustomerList.length === 0) {
            this.customerListInPopup = true;
          }
          else if (this.CustomerList.length !== 0) {
            this.customerListInPopup = false;
          }
        });
  }

  loadDataForTaxCancel(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoiceCancel;
          this.TrnMainObj.VoucherAbbName = 'SC';
          this.TrnMainObj.VoucherPrefix = 'SC';
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;


          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          this.getVoucherNumberForTaxCancel();

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }
  DOFULLRETURN: number = 0;
  // There is already DOFULLRETURN which was maped on TrnmainObj. 
  // But when Ref Loaded the data it will auto initilize
  loadDataForSalesReturn(VCHR = '', division = '', phiscalid = '', BARCODE = '', REFBILL = '',TAG = '') {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid, BARCODE,REFBILL,'',TAG).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.DOFULLRETURN = this.DOFULLRETURN;
          this.TrnMainObj.VoucherType = 15;
          this.TrnMainObj.VoucherAbbName = 'CN';
          this.TrnMainObj.VoucherPrefix = 'CN';
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.getVoucherNumber();
          this.ReCalculateBillWithNormal();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);

              console.log("prodlistcn",this.TrnMainObj.ProdList);
        }
      },
      error => {
        console.log("Error",error);
        this.loadingService.hide();
        this.alertService.error(error.error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );


  }


  loadDataForSalesReturnArrpoval(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadSalesReturnForApproval(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }




  loadDataForStockTransferApproval(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadStockIssueDataForPurchaseReturn(VCHR, division, phiscalid) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHR, division, phiscalid).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
          this.TrnMainObj.VoucherPrefix = 'DN';
          this.TrnMainObj.VoucherAbbName = 'DN';

          this.TrnMainObj.VCHRNO = this.TrnMainObj.REFBILL;

          for (let i in this.TrnMainObj.ProdList) {
            this.setAltunitDropDownForView(i);
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 10));
            this.TrnMainObj.ProdList[i].VCHRNO = this.TrnMainObj.REFBILL;
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
          }
          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForPurchaseFromPOORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
          this.TrnMainObj.VoucherPrefix = 'PI';
          this.TrnMainObj.VoucherAbbName = 'PI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].RowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForStockIssueReturn(VCHRNO, division, phiscalid,REFBILL,warehouse,towarehouse) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    console.log('towarehouse',warehouse,towarehouse);
    this.masterService.LoadTransactionForIssueReturn(VCHRNO, division, phiscalid,REFBILL,warehouse).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.StockIssueReturn;
          this.TrnMainObj.VoucherPrefix = 'IR';
          this.TrnMainObj.VoucherAbbName = 'IR';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.BILLTO = warehouse;
          this.TrnMainObj.BILLTOADD = towarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].RowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();

          if(this.TrnMainObj.STOCKMESSAGE){
            if(this.userSetting.ENABLESINGLEBATCHMODE == 1){
              if(this.userSetting.ALLOWMINUSTOCK_STOCKISSUE == 1){
                this.alertService.info(this.TrnMainObj.STOCKMESSAGE);
              }
              else if(this.userSetting.ALLOWMINUSTOCK_STOCKISSUE == 2){
                this.showNegativeBillingAuthForMain = true;
              }
            }
            else{
              this.alertService.info(this.TrnMainObj.STOCKMESSAGE);

            }
          }


          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }



  loadDataForGRNFromPOORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL;
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.GoodsReceived;
          this.TrnMainObj.VoucherPrefix = 'GR';
          this.TrnMainObj.VoucherAbbName = 'GR';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
            this.TrnMainObj.ProdList[i].TEMP_ALTRATE = this.TrnMainObj.ProdList[i].ALTRATE;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }



  loadDataForImportPurchaseFromIMPORDER(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.Purchase;
          this.TrnMainObj.VoucherPrefix = 'PI';
          this.TrnMainObj.VoucherAbbName = 'PI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.CNDN_MODE  = 10;
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse;
          this.TrnMainObj.TRNAC = null;
          this.TrnMainObj.TRNACName = null;
          this.TrnMainObj.IMPORTDETAILS = <IMPORT_DETAILS>{};
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          this.TrnMainObj.AdditionalObj.tag ='IMPORT_PURCHASE';
          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForSalesInoviceFromSO(VCHRNO, division, phiscalid,REFBILL) {
    // console.log("loadDataForSalesInoviceFromSO")
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL

    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.TaxInvoice;
          this.TrnMainObj.VoucherPrefix = 'TI';
          this.TrnMainObj.VoucherAbbName = 'TI';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.LOADFULLFROMQT =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.REFBILL =tempRefbill;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
            console.log("loadDataForSalesInoviceFromSO", this.TrnMainObj.ProdList[i].RATE, this.userSetting.VatConRate);
            if(this.userSetting.ENABLE_VTEXCLUDINGRATE_INSORDER == 0){
              let tempRate = this.TrnMainObj.ProdList[i].RATE
              this.TrnMainObj.ProdList[i].RATE = tempRate / this.userSetting.VatConRate;
              this.TrnMainObj.ProdList[i].ALTRATE = tempRate / this.userSetting.VatConRate;
            }
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForPerfromaInoviceFromSO(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    let tempRefbill = this.TrnMainObj.REFBILL
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.PerformaSalesInvoice;
          this.TrnMainObj.VoucherPrefix = 'PP';
          this.TrnMainObj.VoucherAbbName = 'PP';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.REFBILL = tempRefbill;
          this.TrnMainObj.LOADFULLFROMQT =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.TRNMODE = 'credit';
          this.TrnMainObj.PARAC = this.TrnMainObj.TRNAC;
          this.TrnMainObj.CHALANNOPREFIX = null;
          this.TrnMainObj.MWAREHOUSE = this.userProfile.userWarehouse
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }
          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;

          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  loadDataForSalesOrderFromQuotation(VCHRNO, division, phiscalid,REFBILL) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransaction(VCHRNO, division, phiscalid, undefined, REFBILL).subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.SalesOrder;
          this.TrnMainObj.VoucherPrefix = 'SO';
          this.TrnMainObj.VoucherAbbName = 'SO';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFORDBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN =1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;
          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }

          for (let i in this.TrnMainObj.ProdList) {
            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;
            this.TrnMainObj.ProdList[i].POSTOCK = this.TrnMainObj.ProdList[i].ALTERNATEQUANTIY;
          }

          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }


  assignProductMasterRate(activerowIndex:number,value:number,confatcor:number=0){
    this.TrnMainObj.ProdList[activerowIndex].RATE = value;
    this.TrnMainObj.ProdList[activerowIndex].BASERATE = value;
    this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value;

    
    if(this.TrnMainObj.VoucherType == VoucherTypeEnum.Repack) {
      this.TrnMainObj.ProdList[activerowIndex].ALTRATE = value * confatcor;
    }
   

  }


  loadDataForPurchaseReturn(VCHRNO, division, phiscalid,) {
    this.loadingService.show('Getting Details, Please Wait...');
    this.masterService.LoadTransactionForDebitNote(VCHRNO, division, phiscalid,'FORDEBITNOTE').subscribe(
      data => {
        this.loadingService.hide();
        if (data['status'] === 'ok') {
          this.TrnMainObj = data['result'];
          
          this.TrnMainObj.Mode = 'NEW';
          this.TrnMainObj.VoucherType = VoucherTypeEnum.DebitNote;
          this.TrnMainObj.VoucherPrefix = 'DN';
          this.TrnMainObj.guid = uuid();
          this.TrnMainObj.REFBILL = this.TrnMainObj.VCHRNO;
          this.TrnMainObj.DOFULLRETURN = 1;
          this.TrnMainObj.CHALANNO = null;
          this.TrnMainObj.CHALANNOPREFIX = null;

          if(this.TrnMainObj.STOCKMESSAGE){
            console.log("SHOW STOCK MEAASGE, ENABLESINGLEBATCHMODE", this.userSetting.ENABLESINGLEBATCHMODE, "ALLOWMINUS STOCK", this.userSetting.ALLOWMINUSTOCK_DEBITNOTE )
            if(this.userSetting.ENABLESINGLEBATCHMODE == 1){
              if(this.userSetting.ALLOWMINUSTOCK_DEBITNOTE == 1){
              this.alertService.info(this.TrnMainObj.STOCKMESSAGE);
              }
              else if(this.userSetting.ALLOWMINUSTOCK_DEBITNOTE == 2){
                this.showNegativeBillingAuthForMain = true;
              }
            }
            else{
              this.alertService.info(this.TrnMainObj.STOCKMESSAGE);

            }
          }


          if (this.TrnMainObj.AdditionalObj == null) {
            this.TrnMainObj.AdditionalObj = <any>{};
          }


          for (let i in this.TrnMainObj.ProdList) {
            //this.setAltunitDropDownForView(i);

            this.TrnMainObj.ProdList[i].MFGDATE = ((this.TrnMainObj.ProdList[i].MFGDATE == null) ? '' : this.TrnMainObj.ProdList[i].MFGDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].EXPDATE = ((this.TrnMainObj.ProdList[i].EXPDATE == null) ? '' : this.TrnMainObj.ProdList[i].EXPDATE.toString().substring(0, 7));
            this.TrnMainObj.ProdList[i].BaseSecondaryDiscount = this.TrnMainObj.ProdList[i].SecondaryDiscount;
            this.TrnMainObj.ProdList[i].Quantity = 0;
            this.TrnMainObj.ProdList[i].visible = false;
            this.TrnMainObj.ProdList[i].rowMode = this.buttonMode;

      
            if( this.TrnMainObj.DOFULLRETURN == 1){
              this.TrnMainObj.ProdList[i].BATCH = '';
            }

          }



          this.ReCalculateBillWithNormal();
          this.getVoucherNumber();
          this.getCurrentDate();
          this.TrnMainObj.TRNDATE =
            this.TrnMainObj.TRNDATE == null
              ? ''
              : this.TrnMainObj.TRNDATE.toString().substring(0, 10);
          this.TrnMainObj.TRN_DATE =
            this.TrnMainObj.TRN_DATE == null
              ? ''
              : this.TrnMainObj.TRN_DATE.toString().substring(0, 10);
        }
      },
      error => {
        this.loadingService.hide();
        this.alertService.error(error);
        this.trnmainBehavior.complete();
      },
      () => {
        this.loadingService.hide();
        this.trnmainBehavior.complete();
      }
    );
  }

  assignRateFromProductMaster(activerowIndex:number,value:any){
    
  }

  validatePurchaseQtyInDebitNote(purchaseQty:number,EnteredQty:number,activerowIndex):boolean{
      if(EnteredQty>purchaseQty){
        this.alertService.info(`item :${this.TrnMainObj.ProdList[activerowIndex].ITEMDESC} Entered quantity is gereater than purchase quantity `);
        return false;
      }
      return true;
  }

  validateIssueQtyInStockIssueReturn(issueQty:number,enteredQty:number,activerowindex):boolean{
    if(enteredQty>issueQty){
      this.alertService.info(`item :${this.TrnMainObj.ProdList[activerowindex].ITEMDESC},  Entered quantity: ${enteredQty} is greater than issued quantity: ${issueQty} `);
      return false;
    }
    return true;
  }


  prepareProductDetails() {
    let productDetails = [];
    let i = 0;
    for (let item of this.TrnMainObj.ProdList) {
      let prodDetailObj = <any>{};
      prodDetailObj.color = '';
      prodDetailObj.quantity = item.BASEQUANTITY;
      prodDetailObj.IEMI1 = item.BATCH;
      prodDetailObj.IEMI2 = '';
      //  this.TrnMainObj.ProdList.ProductDetails.
      this.TrnMainObj.ProdList[i].ProductDetails.push(prodDetailObj);
      i++;
    }
  }



  calculateRate(i) {
    let eachRate = this.TrnMainObj.ProdList[i].RATE;
    let conFactor = this.TrnMainObj.ProdList[i].ALTERNATEUNIT;

    if (this.TrnMainObj.ProdList[i].UNIT === 'EACH') {
      return eachRate;
    } else {

    }
  }

  transformDatetoMMddYY(date) {
    return moment(date).format('MM/DD/YYYY')
  }

  reverseEntry(voucherType:VoucherTypeEnum){
    this.TrnMainObj.guid = uuid();
    if(voucherType == VoucherTypeEnum.Purchase){
     // this.TrnMainObj.VoucherType = VoucherTypeEnum.ReversePurchase;
     // this.TrnMainObj.VoucherPrefix = "PX";
     // this.TrnMainObj.VoucherAbbName = "PX";
    }

  
  }


  subtractDates(date1, date2) {
    var dt1 = new Date(date1);
    var dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }
  deteProforma(vchrno) {
    this.masterService
      .masterPostmethod("/deteteProforma", {
        barcode: vchrno
      })
      .subscribe(
        res => {
          if (res.status == "ok") {
            this.alertService.success("Deleted Succesfully")

            this.initialFormLoad(this.TrnMainObj.VoucherType);

          } else {
            this.alertService.error(res.result);
          }
        },
        error => {
          this.alertService.error(error);
        }
      );
  }

  checkBarcode(barCode: string, Warehouse: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();

    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn) {
      var bodydata = { barCode: barCode, Refbill: this.TrnMainObj.REFBILL }      
      return this.masterService.http.post(this.masterService.apiUrl + '/checkBarcodeForSalesReturn', bodydata);
      
    }
    else {

      this.masterService.http.get(`${this.masterService.apiUrl}/checkDuplicateBarCodeDetail?barCode=${barCode}&warehouse=${Warehouse}`).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    }
  }

  checkBarcodeForSalesReturn(barCode: string, Refbill: string) {
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();
    if (!Refbill) { this.alertService.warning("Please select Ref Bill No. first!"); return; }
    var bodydata = { barCode: barCode, Refbill:Refbill  };
    this.masterService.masterPostmethod('/checkBarcodeForSalesReturn', bodydata).subscribe(response => {
      let data = response;
      if (data['status'] == 'ok') {
        returnSubject.next(data);
        returnSubject.unsubscribe();
      }
      else {
        returnSubject.next(data)
        returnSubject.unsubscribe();
      }
    }, error => {
      res.status = 'error'; res.result = error;
      returnSubject.next(res);
      returnSubject.unsubscribe();
    }
    );
    return returnSubject;
  }
  CheckIfSalesReturn(): boolean {
    if (this.TrnMainObj.VoucherType === VoucherTypeEnum.SalesReturn ||
      this.TrnMainObj.VoucherType === VoucherTypeEnum.CreditNote) {
      return true;
    } else {
      false
    }
  }
  ReturnStockReconcile(mcode): number {
    var totalStockEntered = 0
    for (let i of this.TrnMainObj.ProdList) {
      if (i.MCODE == mcode) {
        totalStockEntered += i.ALTERNATEQUANTIY;

      }

    }
    return totalStockEntered;
  }

  getVariantNameFromId = (variantID: string): string => {
    // console.log("getVariantNameFromID", variantID, this.masterService.variantmaster);
    // let variantdeail = this.masterService.variantmaster.filter(x => x.VARIANT == variantID);



    let variantdeail = this.masterService.variantmaster.filter(x => x.VARIANTNAME == variantID);

    if (variantdeail.length) {
      return variantdeail[0].VARIANTNAME;
    } else {
      return ""
    }
  }

  addConsumptionRow(){
    try{
      if(this.TrnMainObj.ConsumptionProdList == null){
        this.TrnMainObj.ConsumptionProdList = [];
      }

      let expitem = this.TrnMainObj.ConsumptionProdList.filter(x => new Date(x.EXPDATE) < new Date(new Date().setDate(new Date().getDate() - 1)) && x.MCODE != null && x.REQEXPDATE ==1 )[0];
      if (expitem != null &&
        this.TrnMainObj.ConsumptionProdList.some(x => x.PTYPE !== 6)) {
        this.alertService.warning(
          'Expired Item Detected : ' + expitem.ITEMDESC + '..Please Review the list...'
        );
        return false;
      }

      let newRow = <TrnProd>{};
      newRow.inputMode = true;
      newRow.MENUCODE = null;
      newRow.ITEMDESC = null;
      newRow.RATE = null;
      newRow.NCRATE = null;
      newRow.AMOUNT = null;
      newRow.INDDISCOUNTRATE = null;
      newRow.INDDISCOUNT = null;
      newRow.DISCOUNT = null;
      newRow.VAT = null;
      newRow.NETAMOUNT = null;
      newRow.ITEMTYPE = null;
      newRow.RECEIVEDTYPE = null;
      newRow.WAREHOUSE = null;
      newRow.BC = null;
      newRow.ALTERNATEQUANTIY = null;
      newRow.PRATE =0;

      this.TrnMainObj.ConsumptionProdList.push(newRow);

      return true;
    } catch(ex){
      return false;
    }
  }

  getAllUnitsForConsumption(activerowIndex) {
    let alternateUnitList = [];
    let MappedalternateUnitList = [];
    this.masterService
      .masterGetmethod(
        '/getAllUnitsOfItem/' +
        this.TrnMainObj.ConsumptionProdList[activerowIndex].MCODE
      )
      .subscribe(
        res => {
          if (res.status === 'ok') {
            if (
              this.TrnMainObj.ConsumptionProdList[activerowIndex].Product == null
            ) {
              this.TrnMainObj.ConsumptionProdList[activerowIndex].Product = <Product>{};
            }
            this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.MCODE = this.TrnMainObj.ConsumptionProdList[activerowIndex].MCODE;
            alternateUnitList = JSON.parse(res.result);
            for (let it of alternateUnitList) {
              let alternateUnitObj = <AlternateUnit>{};
              alternateUnitObj.ALTUNIT = it.UNIT;
              alternateUnitObj.PRATE_A = it.PRATE_A;
              alternateUnitObj.MCODE = it.MCODE;
              alternateUnitObj.ISDEFAULTPUNIT = it.ISDEFAULTPUNIT;
              alternateUnitObj.ISDEFAULT = it.ISDEFAULT;
              alternateUnitObj.CONFACTOR = this.nullToZeroConverter(it.CONFACTOR) == 0 ? 1 : it.CONFACTOR;
              alternateUnitObj.RATE_A = it.RATE_A;
              MappedalternateUnitList.push(alternateUnitObj);
            }

         
            this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits = MappedalternateUnitList;
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj = this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits[0];
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTERNATEUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.ALTUNIT;
            this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTCONFACTOR = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.CONFACTOR;

          
              let isdefaultPIUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].Product.AlternateUnits.filter(x => x.ISDEFAULTPUNIT == 1)[0];
              if (isdefaultPIUNIT != null) {
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNIT = isdefaultPIUNIT.ALTUNIT;
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTCONFACTOR = isdefaultPIUNIT.CONFACTOR;
               

              } else {
                this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNIT = this.TrnMainObj.ConsumptionProdList[activerowIndex].ALTUNITObj.ALTUNIT;
              }
            
          } else {
          }
        },
        error => {
        }
      );

  }

  calculateImportProdTotalValue(){

    this.TrnMainObj.IMPORTDETAILS.TOTALQTY = 0
    this.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE = 0;
    this.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE = 0
    this.TrnMainObj.IMPORTDETAILS.TOTALVAT = 0;
    this.TrnMainObj.IMPORTDETAILS.NETAMOUNT = 0;
    this.TrnMainObj.IMPORTDETAILS.prodList.forEach(x => {
      x.NETAMOUNT = this.nullToZeroConverter(x.NONTAXABLE) + this.nullToZeroConverter(x.TAXABLE) + this.nullToZeroConverter(x.VAT);
      this.TrnMainObj.IMPORTDETAILS.TOTALQTY = this.nullToZeroConverter(this.TrnMainObj.IMPORTDETAILS.TOTALQTY) + this.nullToZeroConverter(x.QUANTITY);
      this.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE = this.nullToZeroConverter(this.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE) + this.nullToZeroConverter(x.TAXABLE);
      this.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE = this.nullToZeroConverter(this.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE) + this.nullToZeroConverter(x.NONTAXABLE);
      this.TrnMainObj.IMPORTDETAILS.TOTALVAT = this.nullToZeroConverter(this.TrnMainObj.IMPORTDETAILS.TOTALVAT) + this.nullToZeroConverter(x.VAT);
      this.TrnMainObj.IMPORTDETAILS.NETAMOUNT = this.nullToZeroConverter(this.TrnMainObj.IMPORTDETAILS.NETAMOUNT) + this.nullToZeroConverter(x.NETAMOUNT);
    })

    
  }


  calculateImportTotalValue(){
    console.log('impr',this.TrnMainObj.IMPORTDETAILS.prodList);
    this.TrnMainObj.IMPORTDETAILS.TOTALQTY=0
    this.TrnMainObj.IMPORTDETAILS.prodList.forEach(x => {
      x.NETAMOUNT = this.nullToZeroConverter(x.NONTAXABLE) + this.nullToZeroConverter(x.TAXABLE) + this.nullToZeroConverter(x.VAT);
      this.TrnMainObj.IMPORTDETAILS.TOTALQTY +=  this.nullToZeroConverter(x.QUANTITY);
      console.log('totalqty',this.TrnMainObj.IMPORTDETAILS.TOTALQTY,this.TrnMainObj.IMPORTDETAILS);
      this.TrnMainObj.IMPORTDETAILS.TOTALTAXABLE +=  this.nullToZeroConverter(x.TAXABLE);
      this.TrnMainObj.IMPORTDETAILS.TOTALNONTAXABLE +=  this.nullToZeroConverter(x.NONTAXABLE);
      this.TrnMainObj.IMPORTDETAILS.TOTALVAT += this.nullToZeroConverter(x.VAT);
      this.TrnMainObj.IMPORTDETAILS.NETAMOUNT +=  this.nullToZeroConverter(x.NETAMOUNT);
    })

    
  }

  ReCalculateBillForConsumption() {
    try {
      this.TrnMainObj.TOTAMNT = 0;
      this.TrnMainObj.TOTALINDDISCOUNT = 0;
      this.TrnMainObj.TOTALPROMOTION = 0;
      this.TrnMainObj.TOTALLOYALTY = 0;
      this.TrnMainObj.DCAMNT = 0;
      this.TrnMainObj.ServiceCharge = 0;
      this.TrnMainObj.TAXABLE = 0;
      this.TrnMainObj.NONTAXABLE = 0;
      this.TrnMainObj.VATAMNT = 0;
      this.TrnMainObj.NETWITHOUTROUNDOFF = 0;
      this.TrnMainObj.TotalWithIndDiscount = 0;
      this.TrnMainObj.TOTALDISCOUNT = 0;
      this.TrnMainObj.TOTALQTY = 0;
      this.TrnMainObj.TOTALWEIGHT = 0;
      this.TrnMainObj.totalAlternateQuantity = 0;
      this.TrnMainObj.totalBaseQuantity = 0;
      this.TrnMainObj.TOTALECSAMOUNT = 0;
      this.TrnMainObj.CONSUMPTIONGROSS = 0;
      this.TrnMainObj.TOTALCONSUMPTIONQTY = 0;
      // let rQty = 0;

      this.TrnMainObj.ConsumptionProdList.forEach(prod => {
        prod.VoucherType = this.TrnMainObj.VoucherType;
        const pType = prod.PTYPE;
        let rQty;

        if (prod.ALTUNIT === prod.BASEUNIT) {
            rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        } else {
          rQty = this.nullToZeroConverter(prod.ALTERNATEQUANTIY) * this.nullToZeroConverter(prod.ALTCONFACTOR);
          prod.RATE = this.nullToZeroConverter(prod.ALTRATE) / this.nullToZeroConverter(prod.ALTCONFACTOR);
        }
        //for consumption
          prod.AltQty = (prod.ALTUNIT === prod.BASEUNIT) ? rQty : prod.ALTERNATEQUANTIY;
          prod.Quantity =
            prod.RealQty = rQty;
          prod.REALQTY_IN = 0;
          prod.ALTQTY_IN = 0;
        
         
        // prod.AMOUNT =0;

  

        prod.AMOUNT = rQty * this.nullToZeroConverter(prod.RATE);
        // primary discount used as Customer FIxed Discount
        // secondary discount as scheme
        // liquidation discount as contract discount
        if (prod.INDDISCOUNTRATE != null && prod.INDDISCOUNTRATE > 0) {
            prod.INDDISCOUNT = ((prod.AMOUNT + this.nullToZeroConverter(prod.ECSAMOUNT)) * prod.INDDISCOUNTRATE) / 100;
        } else {  }
        prod.PrimaryDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) === 0 ?
          0 : (this.nullToZeroConverter(this.TrnMainObj.CUS_FIX_DISCOUNT_PER) * prod.AMOUNT / 100);
        prod.SecondaryDiscount = this.nullToZeroConverter(prod.BaseSecondaryDiscount);
        // prod.LiquiditionDiscount = this.nullToZeroConverter(prod.BaseLiquiditionDiscount) * prod.Quantity;
        if (this.TrnMainObj.Mode !== 'VIEW') {
          prod.LiquiditionDiscount = this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) === 0 ? 0 :
            (this.nullToZeroConverter(this.TrnMainObj.CUS_Remote_Discount) * prod.AMOUNT / 100);
        }

        let disExcludingFlatDiscount =
          this.nullToZeroConverter(prod.INDDISCOUNT) +
          this.nullToZeroConverter(prod.PROMOTION) +
          this.nullToZeroConverter(prod.LOYALTY) +
          this.nullToZeroConverter(prod.PrimaryDiscount) +
          this.nullToZeroConverter(prod.SecondaryDiscount) +
          this.nullToZeroConverter(prod.LiquiditionDiscount);
        let totalAmt = 0;
        this.TrnMainObj.ConsumptionProdList.forEach(x => {
          totalAmt +=
            (rQty * this.nullToZeroConverter( x.RATE) ) -
            this.nullToZeroConverter(x.INDDISCOUNT) -
            this.nullToZeroConverter(x.PROMOTION) -
            this.nullToZeroConverter(x.LOYALTY) -
            this.nullToZeroConverter(x.PrimaryDiscount) -
            this.nullToZeroConverter(x.SecondaryDiscount) -
            this.nullToZeroConverter(x.LiquiditionDiscount);
        });
        if (totalAmt === 0) {
          prod.FLATDISCOUNT = 0;
        }
        else { }
        prod.DISCOUNT =
          disExcludingFlatDiscount + this.nullToZeroConverter(prod.FLATDISCOUNT);

        // prod.RATE = (this.nullToZeroConverter(prod.RATE)* this.nullToZeroConverter(prod.ALTCONFACTOR))
        if (prod.ISSERVICECHARGE === 1 && this.userSetting.EnableServiceCharge === 1) {
          prod.SERVICETAX =
            (prod.AMOUNT - prod.DISCOUNT) *
            this.setting.appSetting.ServiceTaxRate;
        }
        prod.NCRATE = prod.RATE * prod.EXRATE;
        prod.GSTRATE = prod.ISVAT == 1 ? 13 : 0;


       
       prod.NETAMOUNT = prod.TAXABLE + prod.NONTAXABLE + prod.VAT + this.nullToZeroConverter(prod.SERVICETAX);;
   

        this.TrnMainObj.TOTAMNT += this.nullToZeroConverter(prod.AMOUNT);
        this.TrnMainObj.CONSUMPTIONGROSS += this.nullToZeroConverter(prod.Quantity * prod.RATE);
        console.log("MULTIPLY",  this.TrnMainObj.CONSUMPTIONGROSS)
        this.TrnMainObj.TOTALINDDISCOUNT += this.nullToZeroConverter(prod.INDDISCOUNT);
        this.TrnMainObj.TOTALLOYALTY += this.nullToZeroConverter(prod.LOYALTY);
        this.TrnMainObj.TOTALPROMOTION += this.nullToZeroConverter(prod.PROMOTION);
        this.TrnMainObj.DCAMNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.ServiceCharge += this.nullToZeroConverter(prod.SERVICETAX);
        this.TrnMainObj.TAXABLE += this.nullToZeroConverter(prod.TAXABLE);
        this.TrnMainObj.NONTAXABLE += this.nullToZeroConverter(prod.NONTAXABLE);
        this.TrnMainObj.VATAMNT += this.nullToZeroConverter(prod.VAT);
        this.TrnMainObj.NETWITHOUTROUNDOFF += this.nullToZeroConverter(prod.NETAMOUNT);
        this.TrnMainObj.TotalWithIndDiscount += this.nullToZeroConverter(prod.TOTAL);
        this.TrnMainObj.TOTALDISCOUNT += this.nullToZeroConverter(prod.DISCOUNT);
        this.TrnMainObj.TOTALCONSUMPTIONQTY += rQty;
        // this.TrnMainObj.TOTALCONSUMPTIONQTY += rQty;
        this.TrnMainObj.TOTALWEIGHT += this.nullToZeroConverter(prod.WEIGHT);
        this.TrnMainObj.totalAlternateQuantity += this.nullToZeroConverter(prod.ALTERNATEQUANTIY);
        this.TrnMainObj.totalBaseQuantity += this.nullToZeroConverter(prod.BASEQUANTITY);

        this.TrnMainObj.TOTALPRIMARYDISCOUNT += this.nullToZeroConverter(prod.PrimaryDiscount);
        this.TrnMainObj.TOTALSECONDARYDISCOUNT += this.nullToZeroConverter(prod.SecondaryDiscount);
        this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT += this.nullToZeroConverter(prod.LiquiditionDiscount);

        this.TOTALALLDISCOUNT = this.nullToZeroConverter(this.TrnMainObj.TOTALINDDISCOUNT) + this.nullToZeroConverter(this.TrnMainObj.TOTALPRIMARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALSECONDARYDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALLIQUIDATIONDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALQPSDISCOUNT) +
          this.nullToZeroConverter(this.TrnMainObj.TOTALFLATDISCOUNT);

      });

      this.TrnMainObj.NETAMNT = Math.round(
        this.nullToZeroConverter(this.TrnMainObj.NETWITHOUTROUNDOFF)
      );
      this.TrnMainObj.ROUNDOFF =
        this.TrnMainObj.NETAMNT - this.TrnMainObj.NETWITHOUTROUNDOFF;

    } catch (error) {
      this.alertService.error('ERROR ON BILL CALCULATION:' + error + '...Please Contact To Administrator');
    }
  }

  preventNavigation() {
    if (!this.saveChanges) {
      this.preventNavigationService.preventNavigation(true);
    } else {
      this.preventNavigationService.preventNavigation(false);
    }
  }

  GetRecurringLogDetails(VCHRNO,DIVISION,PHISCALID){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();

      this.masterService.http.get(`${this.masterService.apiUrl}/getRecurringLogDetails?VCHRNO=${VCHRNO}&DIVISION=${DIVISION}&PHISCALID=${PHISCALID}`).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        } 
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;    
  }

  checkBatchInSystem(activeIndex:number){
     let mcode = this.TrnMainObj.ProdList[activeIndex].MCODE;
     let batch = this.TrnMainObj.ProdList[activeIndex].BATCH;
     this.masterService.http.get(`${this.masterService.apiUrl}/CheckEnteredBatch?mcode=${mcode}&batch=${batch}`).subscribe((res:any)=>{
         if(res.status == 'ok'){
           if(res.result && res.result?.length >0){
            if(confirm(`Batch:${batch} is already register in the system.Do you Want to continue with this batch?
            `)){
                console.log('ras',res,res.result);
                this.TrnMainObj.ProdList[activeIndex].ALTRATE = res.result[0].LANDINGCOST;
                this.TrnMainObj.ProdList[activeIndex].RATE = res.result[0].LANDINGCOST;
          
              this.TrnMainObj.ProdList[activeIndex].MFGDATE = res.result[0].MFGDATE?.toString().substring(0,7);
              this.TrnMainObj.ProdList[activeIndex].EXPDATE = res.result[0].EXPDATE?.toString().substring(0,7);
            }else{
              this.TrnMainObj.ProdList[activeIndex].BATCH = "";
            }


           }
         }
     })
  }

  calculateBatchAdjustmentVariance(){    
    for (const iterator of this.TrnMainObj.ProdList) {
       iterator.AMOUNT = ((iterator.VRATE*iterator.Quantity)-(iterator.INVRATE  * iterator.Quantity));
    }  
  }


  getAllSellingPirceOfItem(mcode,vchrno){
    let res = { status: "error", result: "" };
    let returnSubject: Subject<any> = new Subject();

      this.masterService.http.get(`${this.masterService.apiUrl}/getAllSellingPirceOfItem?mcode=${mcode}&vchrno=${vchrno}`).subscribe(response => {
        let data = response;
        if (data['status'] == 'ok') {
          returnSubject.next(data);
          returnSubject.unsubscribe();
        }
        else {
          returnSubject.next(data)
          returnSubject.unsubscribe();
        }
      }, error => {
        res.status = 'error'; res.result = error;
        returnSubject.next(res);
        returnSubject.unsubscribe();
      }
      );
      return returnSubject;
    
  }
  BatchAdjustment(){
  return this.masterService.http.get(`${this.masterService.apiUrl}/GetNegativeItemListOnly`);
  }

  AutoLoadNegativeStock(arr:string[]){
    return this.masterService.http.post(this.masterService.apiUrl + '/AutoLoadNegativeStock', arr);
  }
  

}





export interface ProdListMode {
  mode: string;
  selectedRow: TrnMain;
}

